import { LoginForm } from '../organisms';
import { PanelTemplate } from '../templates/panel-template';


export type LoginPageProps = {
	onLogIn?: () => void;
};
export const LoginPage = ({ onLogIn }: LoginPageProps) => (
	<PanelTemplate title="Log In">
		<LoginForm onLogIn={ onLogIn }/>
	</PanelTemplate>
);