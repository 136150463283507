import type { RouteProps } from '../../util';
import { useSubject } from 'ecce-preact';
import { useAccountController } from '../../controllers/controllers-context';
import { Content, Divider, Heading, LoginForm, Panel } from '../components';
import { EmailVerification } from '../components/organisms/email-verification';


export type VerifyEmailRouteProps = RouteProps & {
	code?: string;
};
export const VerifyEmailRoute = ({ code = '' }: VerifyEmailRouteProps) => {
	const accountController = useAccountController();
	useSubject(accountController, 'state');
	
	if(accountController.state.kind === 'unauthorized') {
		return (
			<Content innerClassName="mrg-auto" width="xs">
				<Panel className="stack">
					<Heading level={ 1 }>Log In</Heading>
					<Divider/>
					<LoginForm/>
				</Panel>
			</Content>
		);
	}
	
	return (
		<Content innerClassName="mrg-auto" width="xs">
			<Panel className="stack">
				<EmailVerification code={ code }/>
			</Panel>
		</Content>
	);
};