import { route } from 'preact-router';
import { useEffect } from 'preact/hooks';
import { useBillingController } from '../../../controllers';
import { Spinner } from '../atoms';
import './checkout-complete-page.scss';


export const CheckoutCompletePage = () => {
	const billingController = useBillingController();
	
	useEffect(() => {
		const abortController = new AbortController();

		billingController.waitForPendingPaymentsToComplete(abortController.signal)
			.then(() => {
				if(abortController.signal.aborted) {
					return;
				}

				route('/billing', true);
			});

		return () => { abortController.abort(); };
	}, [billingController]);
	
	return (
		<div className="checkout-complete-page">
			<Spinner size="lg"/>
		</div>
	);
};