if(process.env.NODE_ENV === 'development') {
	require('preact/debug');
}

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { render } from 'preact';
import { App } from './app';
import { ControllersProvider, makeControllers } from './controllers/controllers-context';
import { ErrorBoundary } from './util';


dayjs.extend(relativeTime);

window.addEventListener('load', async () => {
	const globalControllers = makeControllers();
	await globalControllers.accountController.refreshDetails();

	const rootEl = document.getElementById('root');
	if(!rootEl) {
		throw new Error('Missing #root.');
	}
	
	const Root = () => (
		<ControllersProvider controllers={ globalControllers }>
			<ErrorBoundary>
				<App/>
			</ErrorBoundary>
		</ControllersProvider>
	);
	
	render(<Root/>, rootEl);
});