import type { ClassNameProp } from '../../../util';
import classNames from 'classnames';
import './spinner.scss';


export type SpinnerProps = ClassNameProp & {
	size?: 'sm' | 'md' | 'lg'
	color?: 'light' | 'dark';
};
export const Spinner = ({ className, size = 'lg', color = 'light' }: SpinnerProps) => (
	<div className={ className }>
		<svg
			className={ classNames('spinner', 'spinner--' + size, 'spinner--' + color) }
			viewBox="0 0 100 100"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="50" cy="50" r="45"/>
		</svg>
	</div>
);