import type { JSX } from 'preact';
import type { ChildrenProp, ClassNameProp, TagProp } from '../../../util';
import classNames from 'classnames';
import { h } from 'preact';
import './typography.scss';


export type TextSize = 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
export type TypographyProps = ClassNameProp & TagProp & ChildrenProp & {
	id?: string;
	font?: 'sans' | 'mono' | 'display';
	size?: TextSize;
	align?: 'start' | 'center' | 'end';
	weight?: 'normal' | 'bold';
	overImage?: boolean;
	secondary?: boolean;
	tooltip?: string;
	style?: string | JSX.CSSProperties;
};
export const Typography = ({ id, className, tag = 'div', font='sans', size='md', align='start', weight='normal', overImage, secondary, tooltip, style, children }: TypographyProps) => (
	h(tag, {
		id,
		className: classNames(
			className,
			'typography',
			'typography--font-' + font,
			'typography--size-' + size,
			'typography--align-' + align,
			'typography--weight-' + weight,
			{
				'typography--over-image': overImage,
				'typography--secondary': secondary,
			}
		),
		title: tooltip,
		style,
		children,
	})
);

export const Bold = ({ tag = 'span', className, children }: TagProp & ClassNameProp & ChildrenProp) => (
	h(tag, {
		className: classNames(className, 'typography--weight-bold'),
		children,
	})
);


export type HeadingLevel = 1 | 2 | 3;
const HEADING_LEVEL_SIZES: Record<HeadingLevel, TextSize> = {
	1: 'xxl',
	2: 'xl',
	3: 'lg',
};

export type HeadingProps = ClassNameProp & ChildrenProp & Omit<TypographyProps, 'tag'> & {
	level: HeadingLevel;
};
export const Heading = ({ level, children, ...props }: HeadingProps) => (
	<Typography
		tag={ ('h' + level) as keyof JSX.IntrinsicElements }
		size={ HEADING_LEVEL_SIZES[level] }
		font={ level === 1 ? 'display' : undefined }
		weight="bold"
		{ ...props }
	>
		{ children }
	</Typography>
);