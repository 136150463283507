import type { RouteProps } from '../../util';
import { route } from 'preact-router';
import { useAccountController } from '../../controllers';
import { DashboardCancelBilling } from '../components/pages/dashboard/dashboard-cancel-billing';


export const CancelBillingRoute = (_: RouteProps) => {
	const accountController = useAccountController();
	
	if(accountController.state.kind === 'unauthorized') {
		route('/', true);
		return null;
	}

	if(accountController.state.kind !== 'ok') {
		return null;
	}

	const { billing } = accountController.state.details;
	if(billing.billingStatus !== 'subscribed' && billing.billingStatus !== 'failed') {
		route('/billing', true);
		return null;
	}

	
	return (
		<DashboardCancelBilling/>
	);
};