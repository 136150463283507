import type { Ref } from 'preact/hooks';
import type { AlertSeverity } from './alert';
import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from '@bearing-ctrl/common';
import { useCallback, useEffect, useRef, useState } from 'preact/hooks';
import { Input } from '../atoms';
import { Alert } from './alert';
import { FormField } from './form';


type PasswordFieldDescriptionProps = {
	touched: boolean;
	valid: boolean;
	message: string;
};
const PasswordFieldDescription = ({ touched, valid, message }: PasswordFieldDescriptionProps) => {
	let severity: AlertSeverity = 'info';
	if(valid) {
		severity = 'success';
	} else if(touched) {
		severity = 'error';
	}

	return (
		<Alert
			severity={ severity }
			message={ message }
			size="md"
			icon={ severity === 'info' ? 'point' : undefined }
		/>
	);
};

export type NewPasswordInputsValidity = 'valid' | 'short' | 'long' | 'match';

export type NewPasswordInputsProps = {
	id: string;
	value: string;
	onValueChange: (nextValue: string) => void;
	confirmValue: string;
	onConfirmValueChange: (nextConfirmValue: string) => void;
	disabled?: boolean;
	passwordInputRef?: Ref<HTMLInputElement>;
};
export const NewPasswordInputs = ({ id, value, onValueChange, confirmValue, onConfirmValueChange, disabled, passwordInputRef }: NewPasswordInputsProps) => {
	const [ passwordTouched, setPasswordTouched ] = useState(false);
	const handlePasswordBlur = useCallback(() => {
		setPasswordTouched(true);
	}, []);
	
	const passwordValid = value.length >= PASSWORD_MIN_LENGTH;

	const [ confirmTouched, setConfirmTouched ] = useState(false);
	const handleConfirmBlur = useCallback(() => {
		setConfirmTouched(true);
	}, []);

	const [ confirmValid, setConfirmValid ] = useState(false);
	const confirmInputRef = useRef<HTMLInputElement>(null);
	useEffect(() => {
		const confirmInput = confirmInputRef.current;
		if(!confirmInput) {
			throw new Error('Missing confirm input.');
		}

		const nextConfirmValid = !!confirmValue.length && value === confirmValue;
		setConfirmValid(nextConfirmValid);
		confirmInput.setCustomValidity(nextConfirmValid ? '' : 'Passwords must match');
	}, [ value, confirmValue ]);
	
	return (
		<>
			<FormField
				id={ id + 'Password' }
				label="Password"
				description={ (
					<PasswordFieldDescription
						message={ `At least ${PASSWORD_MIN_LENGTH} characters long` }
						touched={ passwordTouched }
						valid={ passwordValid }
					/>
				) }
				input={ (
					<Input
						ref={ passwordInputRef }
						type="password"
						autoComplete="new-password"
						value={ value }
						onValueChange={ onValueChange }
						minLength={ PASSWORD_MIN_LENGTH }
						maxLength={ PASSWORD_MAX_LENGTH }
						onBlur={ handlePasswordBlur }
						required
						disabled={ disabled }
					/>
				) }
			/>
			<FormField
				id={ id + 'Confirm' }
				label="Confirm Password"
				description={ (
					<PasswordFieldDescription
						message="Passwords must match"
						touched={ confirmTouched }
						valid={ confirmValid }
					/>
				) }
				input={ (
					<Input
						ref={ confirmInputRef }
						type="password"
						autoComplete="new-password"
						value={ confirmValue }
						onValueChange={ onConfirmValueChange }
						minLength={ PASSWORD_MIN_LENGTH }
						maxLength={ PASSWORD_MAX_LENGTH }
						onBlur={ handleConfirmBlur }
						required
						disabled={ disabled }
					/>
				) }
			/>
		</>
	);
};