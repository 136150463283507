import type { ChildrenProp, ClassNameProp, TagProp } from '../../../util';
import type { IconName } from './icon';
import type { HeadingProps } from './typography';
import classNames from 'classnames';
import { h } from 'preact';
import { Icon } from './icon';
import { Heading } from './typography';
import { Divider } from './divider';
import './panel.scss';


export type PanelProps = TagProp & ClassNameProp & ChildrenProp & {
	padding?: 'sm' | 'md';
	opaque?: boolean;
};
export const Panel = ({ tag = 'div', className, padding='md', opaque, children }: PanelProps) => {
	return h(tag, {
		className: classNames(
			'panel',
			'panel--pad-' + padding,
			{
				'panel--opaque': opaque,
			},
			className
		),
		children,
	});
};

export type PanelTitleProps = ClassNameProp & {
	headingLevel?: HeadingProps['level'];
	icon?: IconName;
	title: string;
};
export const PanelTitle = ({ headingLevel = 2, icon, title }: PanelTitleProps) => (
	<div>
		<Heading className="panel-title" level={ headingLevel }>
			<Icon icon={ icon }/>
			<span>{ title }</span>
		</Heading>
		<Divider/>
	</div>
);

