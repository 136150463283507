import type { DocumentationDef } from '../../../../documentation';
import classNames from 'classnames';
import { useCallback, useEffect } from 'preact/hooks';
import { route } from 'preact-router';
import { Content, Divider, Heading, Typography } from '../../atoms';
import { SidebarTemplate } from '../../templates/sidebar-template';
import './documentation-page.scss';
import { useBreakpoint } from '../../../../util';


export type DocumentationPageProps = {
	documentation: DocumentationDef[];
	basePath: string;
	currentPath?: string;
};

const scrollToHash = (smooth: boolean) => {
	const id = window.location.hash.replace('#', '');
	if(!id) {
		return;
	}


	const el = document.getElementById(id);
	if(!el) {
		return;
	}

	window.scrollTo({ top: el.offsetTop - 64, behavior: smooth ? 'smooth' : 'auto' });
};

const DocumentationNav = ({ documentation, basePath, currentPath }: DocumentationPageProps) => {
	const handleSectionClick = useCallback(() => {
		setTimeout(() => scrollToHash(true));
	}, []);
	
	useEffect(() => {
		scrollToHash(false);

		const handleHashChange = () => {
			scrollToHash(false);
		};

		window.addEventListener('hashchange', handleHashChange);
		return () => { window.removeEventListener('hashchange', handleHashChange); };
	}, []);
	
	useEffect(() => {
		window.scrollTo({ top: 0 });
	}, [currentPath]);
	
	return (
		<nav className="documentation-page__nav">
			{ documentation.map(doc => {
				const active = doc.path === currentPath;
				const uri = basePath + doc.path;
				return (
					<section
						className="documentation-page__nav-section"
						key={ doc.path }
					>
						<a
							className={ classNames('documentation-page__doc-link', {
								'documentation-page__doc-link--active': active,
							}) }
							href={ active ? undefined : uri }
						>
							{ doc.title }
						</a>
						{ active && (
							<>
								<Divider/>
								<ol>
									{ doc.sections.map(section => (
										<li className="documentation-page__section-link" key={ section.href }>
											<a
												href={ uri + section.href }
												onClick={ handleSectionClick }
											>
												<Typography>
													{ section.title }
												</Typography>
											</a>
										</li>
									)) }
								</ol>
								<Divider/>
							</>
						)}
					</section>
				);
			})}
		</nav>
	);
};

const DocumentationPageContent = ({ currentPath, documentation }: DocumentationPageProps) => {
	return (
		<>
			{ documentation.map(doc => {
				if(doc.path !== currentPath) {
					return null;
				}
			
				return (
					<Content key={ doc.path } width="md" className="documentation-page__content copy">
						<Heading level={ 1 }>{doc.title}</Heading>
						{doc.content()}
					</Content>
				);
			})}
		</>
	);
};

export const DocumentationPage = (props: DocumentationPageProps) => {
	const large = useBreakpoint('md');
	
	if(!props.currentPath) {
		route(props.basePath + props.documentation[0].path, true);
		return null;
	}

	if(large) {
		return (
			<SidebarTemplate
				className="documentation-page"
				sidebar={ <DocumentationNav { ...props }/> }
			>
				<DocumentationPageContent { ...props }/>
			</SidebarTemplate>
		);
	}

	return (
		<div className="mobile-documentation-page">
			<Content>
				<DocumentationNav { ...props }/>
			</Content>
			<DocumentationPageContent { ...props }/>
		</div>
	);
};