import type { AdminUserDetails } from '@bearing-ctrl/common';
import type { JSX } from 'preact/jsx-runtime';
import type { ClassNameProp } from '../../../../../util';
import { isNotNullish } from '../../../../../util';
import { Icon } from '../../../atoms';


const getLines = (user: AdminUserDetails): string[] => {
	return [
		user.address,
		user.city,
		user.state,
		user.country,
		user.zip,
	].filter(isNotNullish);
};

export const AdminUserAddress = (p: ClassNameProp & { user: AdminUserDetails }): JSX.Element => {
	return (
		<address className={ p.className }>
			{ getLines(p.user).map(line => (
				<>{line}<br/></>
			))}
			{ p.user.phone && (
				<a className="flow--sm flex-align-center" href={ 'tel:' + p.user.phone }>
					<Icon icon="phone" size="sm"/>
					{ p.user.phone }
				</a>
			)}
		</address>
	);
};
