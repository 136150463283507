import { useBreakpoint } from '../../../../../util';
import { ActiveUsersOverview } from './active-users-list';
import { ActiveUsersTable } from './active-users-table';
import './active-users-view.scss';


export const ActiveUsersView = () => {
	const large = useBreakpoint('md');
	return large
		? <ActiveUsersTable/>
		: <ActiveUsersOverview/>;
};