import type { ClassNameProp } from '../../../util';
import { cloneElement } from 'preact';


const ICONS = {
	'hamburger': (
		<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-menu-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
			<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
			<line x1="4" y1="6" x2="20" y2="6"/>
			<line x1="4" y1="12" x2="20" y2="12"/>
			<line x1="4" y1="18" x2="20" y2="18"/>
		</svg>
	),
	'close': (
		<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
			<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
			<line x1="18" y1="6" x2="6" y2="18"/>
			<line x1="6" y1="6" x2="18" y2="18"/>
		</svg>
	),
	'error': (
		<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-alert-octagon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
			<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
			<path d="M8.7 3h6.6c.3 0 .5 .1 .7 .3l4.7 4.7c.2 .2 .3 .4 .3 .7v6.6c0 .3 -.1 .5 -.3 .7l-4.7 4.7c-.2 .2 -.4 .3 -.7 .3h-6.6c-.3 0 -.5 -.1 -.7 -.3l-4.7 -4.7c-.2 -.2 -.3 -.4 -.3 -.7v-6.6c0 -.3 .1 -.5 .3 -.7l4.7 -4.7c.2 -.2 .4 -.3 .7 -.3z"/>
			<line x1="12" y1="8" x2="12" y2="12"/>
			<line x1="12" y1="16" x2="12.01" y2="16"/>
		</svg>
	),
	'warning': (
		<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-alert-triangle" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
			<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
			<path d="M12 9v2m0 4v.01"/>
			<path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"/>
		</svg>
	),
	'success': (
		<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-check" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
			<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
			<circle cx="12" cy="12" r="9"/>
			<path d="M9 12l2 2l4 -4"/>
		</svg>
	),
	'info': (
		<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-info-circle" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
			<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
			<circle cx="12" cy="12" r="9"/>
			<line x1="12" y1="8" x2="12.01" y2="8"/>
			<polyline points="11 12 12 12 12 16 13 16"/>
		</svg>
	),
	'point': (
		<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-point" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
			<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
			<circle cx="12" cy="12" r="4"/>
		</svg>
	),
	'mail': (
		<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-mail" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
			<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
			<rect x="3" y="5" width="18" height="14" rx="2"/>
			<polyline points="3 7 12 13 21 7"/>
		</svg>
	),
	'tower': (
		<svg aria-hidden version="1.1" viewBox="0 0 174.2016 155" width="24" height="24" stroke="currentColor" fill="currentColor">
			<g
				transform="translate(-2.9997427,-1.2077167)"
				id="layer1"
			>
				<path
					transform="matrix(0.26458333,0,0,0.26458333,2.9997427,1.2077173)"
					d="m 160.14,217.59999 h 33.73 c 11.01,0 18.61,-10.83 14.86,-21.18 -4.93,-13.58 -7.55,-27.98 -7.55,-42.82 0,-14.84 2.62,-29.24 7.55,-42.82 3.76,-10.34999 -3.85,-21.179991 -14.86,-21.179991 h -33.73 c -7.01,0 -13.46,4.49 -15.41,11.230001 -4.89,16.97999 -7.53,34.64999 -7.53,52.76999 0,18.12 2.64,35.79 7.54,52.76 1.94,6.74 8.39,11.24 15.4,11.24 z"
					id="wave-left-inner"
				/>
				<path
					transform="matrix(0.26458333,0,0,0.26458333,2.9997427,1.2077173)"
					d="m 99.120001,48.939998 c 5.639999,-10.62 -1.95,-23.34 -13.96,-23.34 h -35.33 c -6.27,0 -12.14,3.59 -14.74,9.31 -16.49,36.23 -25.8900002,76.339992 -25.8900002,118.689992 0,24.75 3.1200002,68.33 26.6900002,118.86 2.62,5.63 8.42,9.14 14.61,9.14 h 34.84 c 12.02,0 19.609999,-12.74 13.95,-23.37 -49.78,-93.32 -16.71,-178.149992 -0.17,-209.289992 z"
					id="wave-left-outer"
				/>
				<path
					transform="matrix(0.26458333,0,0,0.26458333,2.9997427,1.2077173)"
					d="m 623.26,34.889998 c -2.6,-5.71 -8.46,-9.29 -14.73,-9.29 h -35.42 c -11.98,0 -19.66,12.66 -14.02,23.25 18.27,34.290001 48.42,119.419992 0.28,209.229992 -5.72,10.68 1.8,23.52 13.91,23.52 h 35.23 c 6.27,0 12.13,-3.58 14.73,-9.29 16.53,-36.23 25.96,-76.35 25.96,-118.71 0,-42.36 -9.42,-82.479992 -25.94,-118.709992 z"
					id="wave-right-outer"
				/>
				<path
					transform="matrix(0.26458333,0,0,0.26458333,2.9997427,1.2077173)"
					d="m 498.26,89.599999 h -33.73 c -11.01,0 -18.61,10.830001 -14.86,21.179991 4.93,13.58 7.55,27.98 7.55,42.82 0,14.84 -2.62,29.24 -7.55,42.82 -3.76,10.35 3.85,21.18 14.86,21.18 h 33.73 c 7.02,0 13.46,-4.49 15.41,-11.24 4.9,-16.97 7.53,-34.64 7.53,-52.76 0,-18.12 -2.64,-35.79 -7.54,-52.75999 -1.94,-6.750001 -8.39,-11.240001 -15.4,-11.240001 z"
					id="wave-right-inner"
				/>
				<path
					transform="matrix(0.26458333,0,0,0.26458333,2.9997427,1.2077173)"
					id="tower"
					d="m 280.82,345.59999 48.38,-116.19 48.38,116.19 z m 101.14,-155.88 c 7.05,-10.29 11.2,-22.71 11.2,-36.12 0,-35.35 -28.63,-63.999991 -63.96,-63.999991 -35.32,0 -63.96,28.649991 -63.96,63.999991 0,13.41 4.15,25.83 11.2,36.12 l -130.5,313.41 c -3.4,8.15 0.46,17.52 8.61,20.92 l 29.51,12.31 c 8.15,3.4 17.52,-0.46 20.91,-8.61 l 49.19,-118.15 h 150.07 l 49.2,118.15 c 3.4,8.16 12.76,12.01 20.91,8.61 l 29.51,-12.31 c 8.15,-3.4 12,-12.77 8.61,-20.92 z"
				/>
			</g>
		</svg>
	),
	'credit-card': (
		<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-credit-card" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
			<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
			<rect x="3" y="5" width="18" height="14" rx="3"/>
			<line x1="3" y1="10" x2="21" y2="10"/>
			<line x1="7" y1="15" x2="7.01" y2="15"/>
			<line x1="11" y1="15" x2="13" y2="15"/>
		</svg>
	),
	'key': (
		<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-key" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
			<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
			<circle cx="8" cy="15" r="4"/>
			<line x1="10.85" y1="12.15" x2="19" y2="4"/>
			<line x1="18" y1="5" x2="20" y2="7"/>
			<line x1="15" y1="8" x2="17" y2="10"/>
		</svg>
	),
	'book': (
		<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-book" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
			<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
			<path d="M3 19a9 9 0 0 1 9 0a9 9 0 0 1 9 0"/>
			<path d="M3 6a9 9 0 0 1 9 0a9 9 0 0 1 9 0"/>
			<line x1="3" y1="6" x2="3" y2="19"/>
			<line x1="12" y1="6" x2="12" y2="19"/>
			<line x1="21" y1="6" x2="21" y2="19"/>
		</svg>
	),
	'person': (
		<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
			<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
			<circle cx="12" cy="7" r="4"/>
			<path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"/>
		</svg>
	),
	'arrow-left': (
		<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-left" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
			<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
			<line x1="5" y1="12" x2="19" y2="12"/>
			<line x1="5" y1="12" x2="11" y2="18"/>
			<line x1="5" y1="12" x2="11" y2="6"/>
		</svg>
	),
	'copy': (
		<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-copy" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
			<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
			<rect x="8" y="8" width="12" height="12" rx="2"/>
			<path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"/>
		</svg>
	),
	'user': (
		<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
			<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
			<circle cx="12" cy="7" r="4"/>
			<path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"/>
		</svg>
	),
	'freeagent': (
		<svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 175 167.9" enable-background="new 0 0 175 167.9">
			<polygon fill="#77BC1F" points="125.8,43.5 111.4,82.6 125.3,117.7 98.5,117.7 88.4,144.7 135.8,144.7 145.2,167.9 175,167.9 "/>
			<path fill="#007ABF" d="M139.1,1.1C112-4.2,98.6,10.3,94,22.2c-0.9,2.3-2.1,5.4-8.4,22c-5.1-56-58.2-3.3-84.5-32.7 c-4.1,25.4,5,28.4,5,28.4c-2,8.2,6,17.2,12.6,16c5.9,27.4,63.5-12.1,49.9,32.6c-13,34-30.2,79.2-30.2,79.2h32.4 c0,0,45-120.6,47.6-126.2c3.5-7.5,14.7-10.4,23.7-4.1l11.6-32.1C147.6,3,142.8,1.9,139.1,1.1z M72.3,58.3 C62,43.5,35.9,64.5,30.3,47.8c-6.6,1.4-9-6.2-7.4-8.9c0,0-5.5-0.9-4.9-8.7C37.9,43.4,76.4,10.2,72.3,58.3z"/>
		</svg>
	),
	'stripe': (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="#635bff">
			<path d="M155.3 154.6c0-22.3 18.6-30.9 48.4-30.9 43.4 0 98.5 13.3 141.9 36.7V26.1C298.3 7.2 251.1 0 203.8 0 88.1 0 11 60.4 11 161.4c0 157.9 216.8 132.3 216.8 200.4 0 26.4-22.9 34.9-54.7 34.9-47.2 0-108.2-19.5-156.1-45.5v128.5a396.1 396.1 0 0 0 156 32.4c118.6 0 200.3-51 200.3-153.6 0-170.2-218-139.7-218-203.9z"/>
		</svg>
	),
	'tool': (
		<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-tool" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
			<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
			<path d="M7 10h3v-3l-3.5 -3.5a6 6 0 0 1 8 8l6 6a2 2 0 0 1 -3 3l-6 -6a6 6 0 0 1 -8 -8l3.5 3.5"/>
		</svg>
	),
	'arrow-right': (
		<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-right" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
			<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
			<line x1="5" y1="12" x2="19" y2="12"/>
			<line x1="13" y1="18" x2="19" y2="12"/>
			<line x1="13" y1="6" x2="19" y2="12"/>
		</svg>
	),
	'clock': (
		<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-clock" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
			<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
			<circle cx="12" cy="12" r="9"/>
			<polyline points="12 7 12 12 15 15"/>
		</svg>
	),
	'refresh': (
		<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-reload" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
			<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
			<path d="M19.933 13.041a8 8 0 1 1 -9.925 -8.788c3.899 -1.002 7.935 1.007 9.425 4.747"/>
			<path d="M20 4v5h-5"/>
		</svg>
	),
	'invoice': (
		<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-file-invoice" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
			<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
			<path d="M14 3v4a1 1 0 0 0 1 1h4"/>
			<path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"/>
			<line x1="9" y1="7" x2="10" y2="7"/>
			<line x1="9" y1="13" x2="15" y2="13"/>
			<line x1="13" y1="17" x2="15" y2="17"/>
		</svg>
	),
	'download': (
		<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-download" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
			<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
			<path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2"/>
			<polyline points="7 11 12 16 17 11"/>
			<line x1="12" y1="4" x2="12" y2="16"/>
		</svg>
	),
	'rocket': (
		<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-rocket" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
			<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
			<path d="M4 13a8 8 0 0 1 7 7a6 6 0 0 0 3 -5a9 9 0 0 0 6 -8a3 3 0 0 0 -3 -3a9 9 0 0 0 -8 6a6 6 0 0 0 -5 3"/>
			<path d="M7 14a6 6 0 0 0 -3 6a6 6 0 0 0 6 -3"/>
			<circle cx="15" cy="9" r="1"/>
		</svg>
	),
	'beta': (
		<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-beta" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
			<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
			<path d="M8 22v-14a4 4 0 0 1 4 -4h.5a3.5 3.5 0 0 1 0 7h-.5h.5a4.5 4.5 0 1 1 -4.5 4.5v-.5"/>
		</svg>
	),
	'gift': (
		<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-receipt-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
			<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
			<path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2"/>
			<path d="M14 8h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5m2 0v1.5m0 -9v1.5"/>
		</svg>
	),
	'phone': (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
			<path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"/>
		</svg>
	),
};

export type IconName = keyof typeof ICONS;

const SIZES = {
	sm: 16,
	md: 24,
	lg: 32,
	xl: 48,
	xxl: 64,
};
export type IconSize = number | keyof typeof SIZES;

export type IconProps = ClassNameProp & {
	icon: IconName | undefined;
	size?: IconSize;
};
export const Icon = ({ className, icon, size = 'md' }: IconProps) => {
	if(!icon) {
		return null;
	}

	const sizeProp = typeof size === 'number' ? size : SIZES[size];

	return cloneElement(ICONS[icon], { class: className, 'aria-hidden': true, width: sizeProp, height: sizeProp });
};