import type { SignupResponse } from '@bearing-ctrl/common';
import type { ClassNameProp } from '../../../util';
import type { JSX } from 'preact/jsx-runtime';
import { useRef, useState } from 'preact/hooks';
import { useAuthController } from '../../../controllers/controllers-context';
import { useAutofocus } from '../../../util';
import { Bold, Input, Typography } from '../atoms';
import { Alert, Form, FormField } from '../molecules';
import { NewPasswordInputs } from '../molecules/new-password-inputs';


type EmailDescriptionProps = {
	signupResponse: SignupResponse | null;
	lastSubmittedValue: string;
};
const EmailDescription = ({ signupResponse, lastSubmittedValue }: EmailDescriptionProps): JSX.Element | null => {
	if(!signupResponse || signupResponse.ok) {
		return null;
	}

	if(signupResponse.errors.includes('email-exists')) {
		return (
			<Alert
				severity="error"
				message="This email address is already registered"
				size="md"
			>
				<Typography size="sm">
					Did you mean to <a href="/login">log in</a>?
				</Typography>
			</Alert>
		);
	}

	if(signupResponse.errors.includes('email-free')) {
		return (
			<Alert
				severity="error"
				message={ `You can't use an email address from ${lastSubmittedValue.split('@')[1] ?? 'that domain'}.` }
				size="md"
			>
				<Typography size="sm">
					Please use your company email address.
				</Typography>
			</Alert>
		);
	}

	return null;
};

const SUBMIT_LABEL = 'Sign Up';

export type SignupFormProps = ClassNameProp & {
	onSignUp?: VoidFunction;
};
export const SignupForm = ({ className, onSignUp }: SignupFormProps) => {
	const authController = useAuthController();
	
	const [ email, setEmail ] = useState('');
	const [ password, setPassword ] = useState('');
	const [ confirmPassword, setConfirmPassword ] = useState('');
	
	const emailInputRef = useRef<HTMLInputElement>(null);
	useAutofocus(emailInputRef);

	const [ working, setWorking ] = useState(false);
	
	const [ response, setResponse ] = useState<SignupResponse | null>(null);
	const [ lastSubmittedEmail, setLastSubmittedEmail ] = useState<string | null>();
	
	const handleSubmit = () => {
		setWorking(true);
		setResponse(null);
		setLastSubmittedEmail(email);
		authController.signup({
			email,
			password,
			acceptTou: true,
			acceptEula: true,
		}).then(response => {
			if(response.ok) {
				onSignUp?.();
				return;
			}

			setResponse(response);
		}).finally(() => {
			setWorking(false);
		});
	};
	
	return (
		<Form
			className={ className }
			submitLabel={ SUBMIT_LABEL }
			onSubmit={ handleSubmit }
			disabled={ working }
			header={ (
				<Typography>
					Currently, we <strong>only</strong> support Powercode©.
				</Typography>
			) }
			footer={ (
				<Typography size="sm">
					By clicking "<Bold>{SUBMIT_LABEL}</Bold>",
					you agree to our <a href="/about/terms-of-use" target="_blank">Terms of Use</a> and
					the <Bold>Bearing</Bold> software <a href="/about/end-user-license-agreement" target="_blank">EULA</a>.
				</Typography>
			) }
		>
			<FormField
				id="signupEmail"
				label="Email Address"
				description={ <EmailDescription signupResponse={ response } lastSubmittedValue={ lastSubmittedEmail ?? '' }/> }
				input={ (
					<Input
						ref={ emailInputRef }
						type="email"
						autoComplete="email"
						value={ email }
						onValueChange={ setEmail }
						required
						disabled={ working }
					/>
				) }
			/>

			<NewPasswordInputs
				id="signupPassword"
				value={ password }
				onValueChange={ setPassword }
				confirmValue={ confirmPassword }
				onConfirmValueChange={ setConfirmPassword }
				disabled={ working }
			/>
		</Form>
	);
};