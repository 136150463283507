import type { ClassNameProp } from '../../../../../util';
import classNames from 'classnames';
import { useAdminDetails } from '../../../../../controllers';
import { Typography } from '../../../atoms';
import { AdminUserLastSeenDate } from '../admin-user-last-seen-date';
import './active-users-list.scss';


export const ActiveUsersOverview = (p: ClassNameProp) => {
	const { activeUsers } = useAdminDetails();

	return (
		<ul className={ classNames(p.className, 'activeUsersList') }>
			{ activeUsers.map(user => (
				<li key={ user.id }>
					<a href={ '/admin/users/' + user.id }>
						<Typography tag="span" className="activeUsersList__name" weight="bold" size="lg">
							{ user.companyName }
						</Typography>
						{ user.lastSuccessfulValidation && (
							<>
								&nbsp;
								<AdminUserLastSeenDate className="activeUsersList__lastSeen" date={ user.lastSuccessfulValidation }/>
							</>
						)}
					</a>
				</li>
			))}
		</ul>
	);
};