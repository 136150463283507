import type { AdminUserDetails } from '@bearing-ctrl/common';
import type { ClassNameProp } from '../../../../../util';
import type { JSX } from 'preact/jsx-runtime';
import type { KeyValueTableRow } from '../../../molecules/key-value-table';
import classNames from 'classnames';
import { KeyValueTable } from '../../../molecules/key-value-table';
import { formatCurrency, formatIsoDate, isNotNullish } from '../../../../../util';
import { AdminUserBillingStatus } from '../../../organisms';
import { Button } from '../../../atoms';
import { useAdminController } from '../../../../../controllers';


const getRows = (user: AdminUserDetails): KeyValueTableRow[] => {
	const rows: KeyValueTableRow[] = [
		{ key: 'Status', value: <AdminUserBillingStatus user={ user }/> },
		isNotNullish(user.nextBillingDate) ? { key: 'Next billing', value: formatIsoDate(user.nextBillingDate) } : null,
		user.cost ? { key: 'Amount', value: formatCurrency(user.cost) } : null,
	].filter(isNotNullish);

	return rows;
};

export const AdminUserBilling = (p: ClassNameProp & { user: AdminUserDetails }): JSX.Element => {
	const admin = useAdminController();

	return (
		<div className={ classNames('stack--sm', p.className) }>
			<KeyValueTable rows={ getRows(p.user) }/>
			{ !p.user.evaluation && p.user.billingStatus === 'beta' && (
				<Button
					className="theme-brand"
					icon="credit-card"
					onClick={ () => admin.activateBillingFromBeta(p.user) }
				>
					Activate Billing
				</Button>
			)}
		</div>
	);
};
