import type { ClassNameProp } from '../../../../../util';
import type { TableData } from '../../../atoms/table';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useMemo } from 'preact/hooks';
import { useAdminDetails } from '../../../../../controllers';
import { formatDate } from '../../../../../util';
import { Typography } from '../../../atoms';
import { Table } from '../../../atoms/table';
import { InfoIcon } from '../../../molecules';
import { AdminUserBillingStatus } from '../admin-user-billing-status';
import { AdminUserLastSeenDate } from '../admin-user-last-seen-date';


export type ActiveUserColumn = (
	| 'Company'
	| 'Email'
	| 'Billing'
	| 'Provider'
	| 'Devices'
	| 'Last Seen'
	| 'Users'
	| 'Version'
	| 'Last Login'
	| 'Next Billing'
);
const NO_DATA = <Typography tag="span" secondary size="sm">No data</Typography>;
const Email = ({ email }: { email: string }) => {
	const [ name, domain ] = email.split('@');
	return <a href={ 'mailto:' +email }>{name}<wbr/>@{ domain }</a>;
};
const HIDE: ActiveUserColumn[] = [ 'Next Billing', 'Last Login' ];
export const ActiveUsersTable = ({ className }: ClassNameProp) => {
	const { activeUsers } = useAdminDetails();
	
	const data = useMemo<TableData<ActiveUserColumn>>(() => ({
		columns: [ 'Company', 'Email', 'Billing', 'Provider', 'Devices', 'Last Seen', 'Users', 'Version', 'Last Login', 'Next Billing' ],
		rows: activeUsers
			.sort((a, b) => {
				const aRecent = !!a.lastSuccessfulValidation && dayjs().diff(a.lastSuccessfulValidation, 'months') >= 1;
				const bRecent = !!b.lastSuccessfulValidation && dayjs().diff(b.lastSuccessfulValidation, 'months') >= 1;

				if(aRecent === bRecent) {
					return a.companyName.localeCompare(b.companyName);
				}

				return aRecent ? 1 : -1;
			})
			.map(user => ({
				'Company': (
					<>
						<span className="active-users-view__company-and-info">
							<a href={ `/admin/users/${user.id}` }>
								<InfoIcon
									className="active-users-view__info-icon"
									placement="right-start"
								>
									<Typography weight="bold">{user.companyName}</Typography>
									<div>
										Next billing: {user.nextBillingDate ? formatDate(user.nextBillingDate) : NO_DATA}
									</div>
									<div>
										Last login: {user.lastLogin ? formatDate(user.lastLogin.date) : NO_DATA}
									</div>
									{ (!!user.stripeCustomer || !!user.freeagentContact) && <hr/> }
									{ user.sites && (
										<div>
											Sites: { user.sites }
										</div>
									)}
								</InfoIcon>
							</a>
							<span>{ user.companyName }</span>
							
						</span>
						<span className="active-users-view__company-name">
							{ user.companyName }
						</span>
					</>
				),
				'Email': <Email email={ user.email }/>,
				'Billing': !user.billingStatus ? NO_DATA : <AdminUserBillingStatus user={ user }/>,
				'Location': `${user.state ? user.state + ', ' : ''}${user.country}`,
				'Provider': user.dataProvider ?? NO_DATA,
				'Devices': user.rfDevices ?? NO_DATA,
				'Sites': user.sites ?? NO_DATA,
				'Last Seen': {
					value: user.lastSuccessfulValidation ? <AdminUserLastSeenDate date={ user.lastSuccessfulValidation }/> : NO_DATA,
					tooltip: user.lastSuccessfulValidation && dayjs(user.lastSuccessfulValidation).fromNow(),
				},
				'Users': {
					value: user.lastLogin ? (
						<>
							<strong>{ user.lastLogin.activeUsers }</strong> <Typography tag="span" size="sm" secondary>{dayjs(user.lastLogin.date).format('D/MM') }</Typography>
						</>
					): NO_DATA,
				},
				'Version': user.version ?? NO_DATA,
				'Last Login': user.lastLogin ? formatDate(user.lastLogin.date) : NO_DATA,
				'Next Billing': user.nextBillingDate ? formatDate(user.nextBillingDate) : NO_DATA,
			})),
	}), [activeUsers]);
	return (
		<Table
			className={ classNames('active-users-table', className) }
			data={ data }
			hide={ HIDE }
		/>
	);
};