import type { JSX } from 'preact/jsx-runtime';


export type DocumentationSectionDef = {
	title: string;
	href: string;
};

export type AdjacentDocumentationSection = {
	title: string;
	path: string;
};

export type DocumentationDef = {
	title: string;
	order: string;
	path: string;
	sections: DocumentationSectionDef[];
	content: () => JSX.Element;
	previous?: AdjacentDocumentationSection;
	next?: AdjacentDocumentationSection;
};