import type { ChildrenProp } from '../util';
import type { AccountDetails, AdminDetails } from '@bearing-ctrl/common';
import { createContext } from 'preact';
import { useContext } from 'preact/hooks';
import { NullContextError } from '../util';
import { ApiClient } from '../api';
import { ErrorController } from './error-controller';
import { BillingController } from './billing-controller';
import { AdminController } from './admin-controller';
import { ReferralController } from './referral-controller';
import { AuthController } from './auth-controller';
import { AccountController } from './account-controller';


export type Controllers = Readonly<{
	accountController: AccountController;
	authController: AuthController;
	billingController: BillingController;
	adminController: AdminController;
	errorController: ErrorController;
}>;
export const makeControllers = (): Controllers => {
	const api = new ApiClient();
	const accountController = new AccountController({ api });
	const referralController = new ReferralController();
	const authController = new AuthController({ api, accountController, referralController });
	const billingController = new BillingController({ api, accountController });
	const adminController = new AdminController({ api, accountController });
	const errorController = new ErrorController({ api });

	return Object.freeze({
		accountController,
		authController,
		billingController,
		adminController,
		errorController,
	});
};

const controllersContext = createContext<Controllers | null>(null);

export type ControllersProviderProps = ChildrenProp & {
	controllers: Controllers;
};
export const ControllersProvider = ({ controllers, children }: ControllersProviderProps) => (
	<controllersContext.Provider value={ controllers }>
		{ children }
	</controllersContext.Provider>
);

export const useControllers = (): Controllers => {
	const ctx = useContext(controllersContext);
	if(!ctx) {
		throw new NullContextError('globalControllersContext', 'GlobalControllersProvider');
	}

	return ctx;
};

export const useAccountController = (): AccountController => (
	useControllers().accountController
);
export const useAccountDetails = (): AccountDetails => {
	const accountController = useAccountController();

	if(!accountController.state.details) {
		throw new Error(`Cannot access AccountController details in state "${accountController.state.kind}"`);
	}

	return accountController.state.details;
};
export const useAuthController = (): AuthController => (
	useControllers().authController
);
export const useBillingController = (): BillingController => (
	useControllers().billingController
);
export const useAdminController = (): AdminController => (
	useControllers().adminController
);
export const useAdminDetails = (): AdminDetails => {
	const adminController = useAdminController();

	if(!adminController.state.details) {
		throw new Error(`Cannot access AdminController details in state "${adminController.state.kind}"`);
	}

	return adminController.state.details;
};
export const useErrorController = (): ErrorController => (
	useControllers().errorController
);