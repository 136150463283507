import type { TSchema , Static } from '@sinclair/typebox';
import { Type } from '@sinclair/typebox';


const nullable = <T extends TSchema>(schema: T) => Type.Union([ schema, Type.Null() ]);

export const userDetailsSchema = Type.Object({
	companyName: Type.String(),
	address: Type.String(),
	city: Type.Optional(Type.String()),
	state: Type.Optional(Type.String()),
	zip: Type.String(),
	country: Type.String(),
	sites: Type.Integer(),
	rfDevices: Type.Integer(),
});
export type UserDetails = Static<typeof userDetailsSchema>;

export const billingStatusSchema = Type.Union([
	Type.Literal('beta'),
	Type.Literal('trial'),
	Type.Literal('subscribed'),
	Type.Literal('cancelled'),
	Type.Literal('failed'),
]);
export type BillingStatus = Static<typeof billingStatusSchema>;

export const BILLING_STATUSES: Readonly<BillingStatus[]> = Object.freeze([
	'beta',
	'trial',
	'subscribed',
	'cancelled',
	'failed',
]);

export const paymentMethodBrandSchema = Type.Union([
	Type.Literal('amex'),
	Type.Literal('diners'),
	Type.Literal('discover'),
	Type.Literal('jcb'),
	Type.Literal('mastercard'),
	Type.Literal('unionpay'),
	Type.Literal('visa'),
	Type.Literal('unknown'),
], { title: 'PaymentMethodBrand' });
export type PaymentMethodBrand = Static<typeof paymentMethodBrandSchema>;

export const paymentMethodDetailsSchema = Type.Object({
	brand: paymentMethodBrandSchema,
	lastFourDigits: Type.String(),
}, { title: 'PaymentMethodDetails' });
export type PaymentMethodDetails = Static<typeof paymentMethodDetailsSchema>;

export const failedPaymentDetailsSchema = Type.Object({
	reference: Type.String(),
	dollars: Type.Number(),
	reason: Type.String(),
}, { title: 'FailedPaymentDetails' });
export type FailedPaymentDetails = Static<typeof failedPaymentDetailsSchema>;

export const paymentStatusSchema = Type.Union([
	Type.Literal('pending'),
	Type.Literal('paid'),
	Type.Literal('failed'),
], { title: 'PaymentStatus' });
export type PaymentStatus = Static<typeof paymentStatusSchema>;

export const invoiceDetailsSchema = Type.Object({
	id: Type.String(),
	reference: Type.String(),
	value: Type.Number(),
	currency: Type.String(),
	status: paymentStatusSchema,
	failureReason: Type.Optional(Type.String()),
	createdDate: Type.String({ format: 'date' }),
	paymentDate: Type.String({ format: 'date' }),
}, { title: 'InvoiceDetails' });
export type InvoiceDetails = Static<typeof invoiceDetailsSchema>;

export const accountBillingDetails = Type.Object({
	stripePublicKey: Type.String(),
	billingStatus: billingStatusSchema,
	dollarsPerDevice: Type.Number(),
	userCost: Type.Number(),
	paymentMethod: Type.Optional(paymentMethodDetailsSchema),
	nextPaymentDate: Type.Optional(Type.String()),
	licenseExpiresDate: Type.Optional(Type.String()),
	evaluation: Type.Optional(Type.Object({
		endDate: Type.String(),
		firstBillingDate: Type.String(),
	})),
	failedPayment: Type.Optional(failedPaymentDetailsSchema),
	invoices: Type.Array(invoiceDetailsSchema),
}, { title: 'AccountBillingDetails' });
export type AccountBillingDetails = Static<typeof accountBillingDetails>;

export const accountDetailsSchema = Type.Object({
	email: Type.String(),
	activatedLicense: Type.Boolean(),
	verifiedEmail: Type.Boolean(),
	beta: Type.Boolean(),
	user: Type.Union([ userDetailsSchema, Type.Null() ]),
	licenseKey: Type.Union([ Type.String(), Type.Null() ]),
	admin: Type.Boolean(),
	billing: accountBillingDetails,
}, { title: 'AccountDetails' });
export type AccountDetails = Static<typeof accountDetailsSchema>;

export const collatedTelemetrySchema = Type.Partial(Type.Object({
	lastActiveDay: Type.Optional(Type.Object({
		activeUsers: Type.Number(),
		date: Type.String(),
	})),
	version: Type.String(),
	dataProvider: Type.String(),
	lastLogin: Type.Optional(Type.Object({
		date: Type.String({ format: 'date' }),
		activeUsers: Type.Integer(),
	})),
}), { title: 'CollatedTelemetry' });
export type CollatedTelemetry = Static<typeof collatedTelemetrySchema>;

export const adminActiveUserOverviewSchema = Type.Intersect([
	Type.Object({
		id: Type.String(),
		email: Type.String(),
		companyName: Type.String(),
		address: Type.Optional(Type.String()),
		city: Type.Optional(Type.String()),
		state: Type.Optional(Type.String()),
		zip: Type.Optional(Type.String()),
		country: Type.Optional(Type.String()),
		phone: Type.Optional(Type.String()),
		rfDevices: Type.Optional(Type.Integer()),
		sites: Type.Optional(Type.Integer()),
		billingStatus: Type.Optional(billingStatusSchema),
		evaluation: Type.Boolean(),
		lastSuccessfulValidation: Type.Optional(Type.String()),
		nextBillingDate: Type.Optional(Type.String({ format: 'date' })),
		stripeCustomer: Type.Optional(Type.String({ format: 'uri' })),
		freeagentContact: Type.Optional(Type.String({ format: 'uri' })),
	}),
	collatedTelemetrySchema,
], { title: 'AdminActiveUserOverview' });
export type AdminActiveUserOverview = Static<typeof adminActiveUserOverviewSchema>;

export const detailedDailyTelemetrySchema = Type.Object({
	date: Type.String(),
	userIds: Type.Array(Type.String()),
});
export type DetailedDailyTelemetry = Static<typeof detailedDailyTelemetrySchema>;
export const detailedTelemetrySchema = Type.Object({
	version: nullable(Type.String()),
	dataProvider: nullable(Type.String()),
	daily: Type.Record(Type.String(), detailedDailyTelemetrySchema),
});
export type DetailedTelemetry = Static<typeof detailedTelemetrySchema>;

export const adminUserDetailsSchema = Type.Object({
	id: Type.String(),
	email: Type.String(),
	companyName: Type.String(),
	address: Type.Optional(Type.String()),
	city: Type.Optional(Type.String()),
	state: Type.Optional(Type.String()),
	zip: Type.Optional(Type.String()),
	country: Type.Optional(Type.String()),
	phone: Type.Optional(Type.String()),
	rfDevices: Type.Optional(Type.Integer()),
	sites: Type.Optional(Type.Integer()),
	billingStatus: Type.Optional(billingStatusSchema),
	evaluation: Type.Boolean(),
	lastSuccessfulValidation: Type.Optional(Type.String()),
	nextBillingDate: Type.Optional(Type.String({ format: 'date' })),
	cost: Type.Union([ Type.Number(), Type.Null() ]),
	freeagentContact: Type.Union([Type.String(), Type.Null()]),
	stripeCustomer: Type.Union([Type.String(), Type.Null()]),
	telemetry: nullable(detailedTelemetrySchema),
	invoices: Type.Array(invoiceDetailsSchema),
}, { title: 'AdminUserDetails' });
export type AdminUserDetails = Static<typeof adminUserDetailsSchema>;

export const adminFreeAgentDetailsSchema = Type.Object({
	oauthUrl: Type.String(),
	connected: Type.Boolean(),
}, { title: 'AdminFreeAgentDetails' });
export type AdminFreeAgentDetails = Static<typeof adminFreeAgentDetailsSchema>;

export const adminBuildDetails = Type.Object({
	version: Type.String(),
	commit: Type.String(),
});
export type AdminBuildDetails = Static<typeof adminBuildDetails>;

export const adminDetailsSchema = Type.Object({
	build: adminBuildDetails,
	activeUsers: Type.Array(adminActiveUserOverviewSchema),
	allUserEmailAddresses: Type.Array(Type.String()),
	pendingBetaRequestEmailAddresses: Type.Array(Type.String()),
	unactivatedUserEmailAddresses: Type.Array(Type.String()),
	freeAgent: adminFreeAgentDetailsSchema,
	enableDevTools: Type.Boolean(),
}, { title: 'AdminDetails' });
export type AdminDetails = Static<typeof adminDetailsSchema>;