import type { JSX } from 'preact/jsx-runtime';
import type { ChildrenProp, ClassNameProp } from '../../../util';
import type { IconName } from './icon';
import classNames from 'classnames';
import { forwardRef } from 'preact/compat';
import { useMemo } from 'preact/hooks';
import { Icon } from './icon';
import './controls.scss';
import { Spinner } from './spinner';


export type ButtonVariant = 'default' | 'primary' | 'danger' | 'invisible' | 'icon';
export type ButtonProps<TElement extends 'button' | 'a'> = JSX.IntrinsicElements[TElement] & {
	innerClassName?: string;
	icon?: IconName;
	variant?: ButtonVariant;
	fullWidth?: boolean;
	visuallyHiddenLabel?: string;
	actionInProgress?: boolean;
};

type ButtonContentProps = ClassNameProp & ChildrenProp & { icon?: IconName, actionInProgress?: boolean };
const ButtonContent = ({ className, icon, actionInProgress, children }: ButtonContentProps) => (
	<div className={ classNames('button__content', className) }>
		{ !actionInProgress && (
			<>
				<Icon icon={ icon }/>
				{ children }
			</>
		)}
		{ actionInProgress && (
			<Spinner size="md"/>
		)}
	</div>
);

const makeButtonClassName = (disabled?: boolean, actionInProgress?: boolean, variant: ButtonVariant = 'default', fullWidth?: boolean, className?: string): string => (
	classNames('button', 'button--' + variant, className, {
		'button--disabled': disabled,
		'button--enabled': !disabled,
		'button--action-in-progress': actionInProgress,
		'button--full-width': fullWidth,
	})
);

export const Button = ({ className, innerClassName, icon, variant, fullWidth, disabled, actionInProgress, visuallyHiddenLabel, children, ...props }: ButtonProps<'button'>) => (
	<button
		className={ makeButtonClassName(disabled, actionInProgress, variant, fullWidth, className) }
		draggable={ false }
		disabled={ disabled || actionInProgress }
		{ ...props }
	>
		{ variant === 'icon' && (
			<Icon icon={ icon }/>
		)}
		{ variant !== 'icon' && (
			<ButtonContent className={ innerClassName } icon={ icon } actionInProgress={ actionInProgress }>
				{ children }
			</ButtonContent>
		)}
		{ visuallyHiddenLabel && (
			<span className="visually-hidden">
				{ visuallyHiddenLabel }
			</span>
		)}
	</button>
);

export const LinkButton = ({ className, innerClassName, variant, fullWidth, children, disabled, href, icon, visuallyHiddenLabel, ...props }: ButtonProps<'a'>) => {
	return (
		<a
			className={ makeButtonClassName(disabled, false, variant, fullWidth, className) }
			href={ disabled ? undefined : href }
			draggable={ false }
			{ ...props }
		>
			<ButtonContent className={ innerClassName } icon={ icon }>
				{children}
			</ButtonContent>
			{ visuallyHiddenLabel && (
				<span className="visually-hidden">
					{ visuallyHiddenLabel }
				</span>
			)}
		</a>
	);
};

export type InputProps = Omit<JSX.IntrinsicElements['input'], 'autofocus' | 'autoFocus'> & {
	onValueChange?: (value: string) => void;
};
export const Input = forwardRef<HTMLInputElement, InputProps>(({ className, onValueChange, onInput, ...props }, ref) => {
	const handleInput = useMemo<JSX.GenericEventHandler<HTMLInputElement> | undefined>(() => {
		if(!onValueChange && !props.onChange && !onInput) {
			return;
		}

		return function (ev) {
			onValueChange?.(ev.currentTarget.value);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(onInput as any)?.(ev);
		};
	}, [onInput, onValueChange, props.onChange]);

	return (
		<input
			{ ...props }
			ref={ ref }
			className={ classNames('input', className) }
			onInput={ handleInput }
		/>
	);
});
