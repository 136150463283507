import type { ClassNameProp } from '../../../util';
import classNames from 'classnames';
import './divider.scss';


export type DividerProps = ClassNameProp & {
	size?: 'sm' | 'md'
};
export const Divider = ({ className, size = 'sm' }: DividerProps) => (
	<hr className={ classNames('divider', 'divider--' + size, className) }/>
);