import type { InvoiceDetails, PaymentStatus } from '@bearing-ctrl/common';
import type { ClassNameProp } from '../../../../../util';
import type { IconName } from '../../../atoms';
import type { AlertSeverity } from '../../../molecules';
import classNames from 'classnames';
import { Fragment } from 'preact/jsx-runtime';
import { formatCurrency, formatDate } from '../../../../../util';
import { LinkButton, Panel, PanelTitle, Typography } from '../../../atoms';
import { Alert } from '../../../molecules';
import './invoice-history-view.scss';


export type InvoiceHistoryViewProps = ClassNameProp & {
	invoices: InvoiceDetails[];
};

const getInvoiceSeverity = (status: PaymentStatus, creditNode: boolean): AlertSeverity => {
	if(creditNode) {
		return 'success';
	}
	
	switch(status) {
		case 'pending': return 'info';
		case 'paid':    return 'success';
		case 'failed':  return 'error';
	}
};

const getInvoiceIcon = (status: PaymentStatus, creditNote: boolean): IconName | undefined => {
	if(creditNote) {
		return 'gift';
	}

	return status === 'pending' ? 'clock' : undefined;
};

type InvoiceViewProps = {
	invoice: InvoiceDetails;
};
const InvoiceView = ({ invoice }: InvoiceViewProps) => {
	const data: [string, string][] = [
		[ 'Issued', formatDate(invoice.createdDate) ],
		[ invoice.status === 'paid' ? 'Paid' : 'Due', formatDate(invoice.paymentDate)],
	];

	const creditNote = invoice.value < 0;
	
	return (
		<Alert
			className="invoice-view"
			innerClassName="invoice-view__content"
			message={ (
				<div className="invoice-view__reference-and-value">
					<Typography size="lg">{ invoice.reference }</Typography>
					<Typography size="lg">{ formatCurrency(invoice.value, invoice.currency) }</Typography>
				</div>
			) }
			icon={ getInvoiceIcon(invoice.status, creditNote) }
			severity={ getInvoiceSeverity(invoice.status, creditNote) }
		>
			<div>
				<div className="invoice-view__details">
					{ !creditNote && (
						data.map(([ key, value ]) => (
							<Fragment key={ key }>
								<Typography weight="bold">{ key }</Typography>
								<Typography>{ value }</Typography>
							</Fragment>
						))
					) }
					{ creditNote && (
						<Typography
							weight="bold"
							className="mrg-auto"
						>
							Credit note
						</Typography>
					)}
				</div>
				{ invoice.failureReason && (
					<Typography weight="bold">
						{ invoice.failureReason }
					</Typography>
				)}
			</div>
			<LinkButton
				icon="download"
				className="invoice-view__download"
				href={ `/api/billing/invoices/${invoice.id}` }
				target="_blank"
				download
			>
				PDF
			</LinkButton>
		</Alert>
	);
};

export const InvoiceHistoryView = (p: ClassNameProp & { invoices: InvoiceDetails[] }) => {
	if(!p.invoices.length) {
		return null;
	}

	return (
		<Panel className={ classNames('invoice-history-view', p.className) }>
			<PanelTitle icon="invoice" title="Invoice History"/>
			<ol className="invoice-history-view__list">
				{ p.invoices.map(invoice => (
					<li key={ invoice.id }>
						<InvoiceView invoice={ invoice }/>
					</li>
				))}
			</ol>
		</Panel>
	);
};