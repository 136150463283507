import type { RouteProps } from '../../util';
import { PasswordReset } from '../components/organisms/password-reset';
import { PanelTemplate } from '../components/templates/panel-template';


export type ResetPasswordRouteProps = RouteProps & {
	code?: string;
};
export const ResetPasswordRoute = ({ code }: ResetPasswordRouteProps) => (
	<PanelTemplate title="Reset Password">
		<PasswordReset code={ code }/>
	</PanelTemplate>
);