import { useSubject } from 'ecce-preact';
import { Router } from 'preact-router';
import { useErrorController } from '../controllers';
import { useConstant } from '../util';
import { AccountLoadingSpinner, NavBar, Typography } from './components';
import { ErrorPage } from './components/pages/error-page';
import { AdminUserRoute, HomeRoute, LoginRoute, RouteController, RouteControllerProvider, SignupRoute, VerifyEmailRoute } from './routes';
import { AccountRoute } from './routes/account-route';
import { AdminRoute } from './routes/admin-route';
import { BillingRoute } from './routes/billing-route';
import { CancelBillingRoute } from './routes/cancel-billing-route';
import { CheckoutCompleteRoute } from './routes/checkout-complete-route';
import { AboutRoutes, DocumentationRoutes } from './routes/documentation-routes';
import { LicenseRoute } from './routes/license-route';
import { ResetPasswordRoute } from './routes/reset-password-route';
import './app.scss';


export const App = () => {
	const errorController = useErrorController();
	useSubject(errorController, 'error');

	const routeController = useConstant(() => new RouteController());
		
	return (
		<RouteControllerProvider value={ routeController }>
			<div className="app surface theme-page">
				<NavBar className="app__head"/>
				
				{ errorController.error && (
					<main className="app__main">
						<ErrorPage/>
					</main>
				)}

				{ !errorController.error && (
					<main className="app__main">
						<Router onChange={ routeController.handleRouteChange }>
							<HomeRoute path="/"/>
							<LicenseRoute path="/license"/>
							<AccountRoute path="/account"/>
							<BillingRoute path="/billing"/>
							<CheckoutCompleteRoute path="/billing/checkout-complete"/>
							<CancelBillingRoute path="/billing/cancel"/>
							
							<LoginRoute path="/login"/>
							<SignupRoute path="/signup"/>
							<VerifyEmailRoute path="/verify-email/:code"/>
							<ResetPasswordRoute path="/reset-password/:code?"/>

							<AboutRoutes path="/about/:subPath?"/>
							<DocumentationRoutes path="/documentation/:subPath?"/>

							<AdminRoute path="/admin"/>
							<AdminUserRoute path="/admin/users/:userId"/>
						</Router>
					</main>
				)}


				<Typography
					tag="footer"
					className="app__foot stack--sm"
					align="center"
				>
					<div>©Estrella Systems Ltd</div>
					<div>
						<a class="pad-r-md" href="/about/privacy-policy">Privacy Policy</a>
						&nbsp;&nbsp;|&nbsp;&nbsp;
						<a class="pad-l-md pad-r-md" href="/about/terms-of-use">Terms of Use</a>
						&nbsp;&nbsp;|&nbsp;&nbsp;
						<a class="pad-l-md" href="/about/cookie-policy">Cookie Policy</a>
					</div>
				</Typography>
			</div>
			<AccountLoadingSpinner/>
		</RouteControllerProvider>
	);
};