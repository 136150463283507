import type { ChildrenProp } from '../../../util';
import { useSubject } from 'ecce-preact';
import { useAccountController } from '../../../controllers';
import { Bold, Content, Divider, Heading, Icon, Panel, Typography } from '../atoms';
import { LoginPage } from '../pages';
import './dashboard-template.scss';


export type DashboardTemplateProps = ChildrenProp & {
	innerClassName?: string;
	title?: string;
	home?: boolean;
};
export const DashboardTemplate = ({ innerClassName, home, title = 'Dashboard', children }: DashboardTemplateProps) => {
	const accountController = useAccountController();
	useSubject(accountController, 'state');

	switch(accountController.state.kind) {
		case 'loading':
			return null;
		case 'unauthorized':
			return <LoginPage/>;
		case 'ok':
			return (
				<Content>
					<Panel className="mrg-auto dashboard-template">
						<header className="dashboard-template__header">
							<Heading level={ 1 }>{ title }</Heading>
							<Typography className="dashboard-template__username" size="sm" secondary>
								Logged in as <Bold>{ accountController.state.details.email }</Bold>
							</Typography>
							<Divider size="md"/>

							{ !home && (
								<>
									<a href="/">
										<Typography className="dashboard-template__back-link" secondary>
											<Icon icon="arrow-left"/>
											Back to Dashboard
										</Typography>
									</a>
									<Divider/>
								</>
							) }
						</header>
						<div className={ innerClassName }>
							{ children }
						</div>
					</Panel>
				</Content>
			);
	}
};