import type { FunctionComponent, Ref } from 'preact';
import type { ClassNameProp } from '../../../util';
import { useEffect, useMemo, useRef, useState } from 'preact/hooks';
import './expander.scss';
import classNames from 'classnames';


type ExpanderStatus = 'open' | 'transitioning' | 'closed';

const DURATIONS = {
	'sm': 200,
	'md': 400,
};
export type ExpanderProps = ClassNameProp & {
	open: boolean;
	duration?: 'sm' | 'md';
	onTransitionComplete?: (open: boolean) => void;
	alwaysTransition?: boolean;
};
export const Expander: FunctionComponent<ExpanderProps> = ({ className, open, duration = 'md', onTransitionComplete, alwaysTransition = false, children }) => {
	const root = useRef<HTMLDivElement>();
	const content = useRef<HTMLDivElement>();

	const transition = useRef<number>();
	const [ targetHeight, setTargetHeight ] = useState(0);

	const [ firstTransition, setFirstTransition ] = useState(!alwaysTransition);

	const [ status, setStatus ] = useState<ExpanderStatus>(open ? 'open' : 'closed');

	useEffect(() => {
		clearTimeout(transition.current);
		setTargetHeight(open ? content.current!.scrollHeight : 0);
		setStatus('transitioning');
		
		transition.current = setTimeout(() => {
			setStatus(open ? 'open' : 'closed');
			onTransitionComplete?.(open);
			setFirstTransition(false);
		}, DURATIONS[duration]) as unknown as number;
	}, [open, children, onTransitionComplete, duration]);
	
	const rootStyle = useMemo(() => ({
		height: targetHeight,
		transition: firstTransition ? undefined : `height ${DURATIONS[duration]}ms`,
	}), [targetHeight, firstTransition, duration]);
	
	return (
		<div
			className={ classNames(className, 'expander') }
			ref={ root as Ref<HTMLDivElement> }
			style={ rootStyle }
		>
			<div
				className={ classNames('expander__content', { 'expander__content--visible': status !== 'closed' }) }
				ref={ content as Ref<HTMLDivElement> }
			>
				{ children }
			</div>
		</div>
	);
};