import type { JSX } from 'preact/jsx-runtime';
import type { ClassNameProp } from '../../../util';
import classNames from 'classnames';
import { useSubject } from 'ecce-preact';
import { useCallback, useEffect, useState } from 'preact/hooks';
import { useAccountController, useAuthController } from '../../../controllers/controllers-context';
import { useRouteController } from '../../routes';
import { Button, Content, Expander, Typography, TowerLogo } from '../atoms';
import './nav-bar.scss';


const NavItems = () => {
	const account = useAccountController();
	useSubject(account, 'state');
	
	const auth = useAuthController();
	
	const handleLogOutClick = useCallback<JSX.GenericEventHandler<HTMLAnchorElement>>(ev => {
		ev.preventDefault();
		auth.logout();
	}, [auth]);
	
	return (
		<>
			<li>
				<a href="/documentation"><Typography size="lg" align="center">Documentation</Typography></a>
			</li>

			{ account.state.kind === 'unauthorized' && (
				<>
					<li>
						<a href="/signup"><Typography size="lg" align="center">Sign up</Typography></a>
					</li>
					<li>
						<a href="/login"><Typography size="lg" align="center">Log in</Typography></a>
					</li>
				</>
			)}

			{ account.state.kind === 'ok' && (
				<>
					<li>
						<a href="/" data-native onClick={ handleLogOutClick }><Typography size="lg" align="center">Log out</Typography></a>
					</li>
					{ account.state.details.admin && (
						<li>
							<a href="/admin"><Typography size="lg" align="center">Admin</Typography></a>
						</li>
					)}
				</>
			)}
		</>
	);
};

export const NavBar = ({ className }: ClassNameProp) => {
	const [ hamburgerOpen, setHamburgerOpen ] = useState(false);

	const handleHamburgerClick = useCallback(() => {
		setHamburgerOpen(prev => !prev);
	}, []);

	const routeController = useRouteController();
	useEffect(() => (
		routeController.onRouteChange(() => { setHamburgerOpen(false); })
	), [routeController]);
	
	
	return (
		<nav className={ classNames('nav-bar', className) }>
			<Content
				className={ classNames('surface theme-panel') }
				innerClassName="nav-bar__content"
				width="lg"
			>
				<div className="nav-bar__banner" role="banner">
					<a href="/">
						<TowerLogo/>
						<Typography tag="h1" font="display" size="xl">
							BEARING
						</Typography>
					</a>
				</div>

				<div>
					<ul className="nav-bar__inline-menu">
						<NavItems/>
					</ul>
					
					<Button
						className="nav-bar__hamburger-button"
						variant="icon"
						icon={ hamburgerOpen ? 'close' : 'hamburger' }
						onClick={ handleHamburgerClick }
						visuallyHiddenLabel={ hamburgerOpen ? 'Close navigation menu' : 'Open navigation menu' }
					/>
				</div>
			</Content>
			<Expander
				className="nav-bar__hamburger-menu"
				duration="sm"
				open={ hamburgerOpen }
			>
				<Content
					className=" theme-panel surface"
					innerTag="ul"
				>
					<NavItems/>
				</Content>
			</Expander>
		</nav>
	);
};