import type { PaymentMethodBrand } from '@bearing-ctrl/common';
import type { ClassNameProp } from '../../../../../../util';
import { Typography } from '../../../../atoms';
import amex from './card-amex.svg';
import mastercard from './card-mastercard.svg';
import visa from './card-visa.svg';


const SOURCES: Partial<Record<PaymentMethodBrand, string>> = {
	amex,
	mastercard,
	visa,
};

export type CardLogoProps = ClassNameProp & {
	brand: PaymentMethodBrand;
};
export const CardLogo = ({ className, brand }: CardLogoProps) => {
	const src = SOURCES[brand];

	if(!src) {
		return (
			<Typography className={ className } weight="bold" size="xl">
				{ brand.toUpperCase() }
			</Typography>
		);
	}

	return (
		<img className={ className } src={ src }/>
	);
};