import type { AdminUserDetails } from '@bearing-ctrl/common';
import type { JSX } from 'preact/jsx-runtime';
import type { IconName } from '../../../atoms';
import type { ClassNameProp } from '../../../../../util';
import classNames from 'classnames';
import { Icon } from '../../../atoms';
import './admin-user-toolbar.scss';


type UserLink = {
	icon: IconName;
	label: string;
	href: string | null;
	disabledTooltip?: string;
};
const getLinks = (user: AdminUserDetails): UserLink[] => [
	{
		icon: 'mail',
		label: user.email,
		href: 'mailto:' + user.email,
	},
	{
		icon: 'freeagent',
		label: 'FreeAgent',
		href: user.freeagentContact,
		disabledTooltip: 'No FreeAgent contact',
	},
	{
		icon: 'stripe',
		label: 'Stripe',
		href: user.stripeCustomer,
		disabledTooltip: 'No Stripe customer',
	},
];

export const AdminUserToolbar = (p: ClassNameProp & { user: AdminUserDetails }): JSX.Element => (
	<div className={ classNames(p.className, 'adminUserToolbar flow--md flex-wrap') }>
		{ getLinks(p.user).map(p => {
			const content = (
				<>
					<Icon icon={ p.icon }/>
					<span>{ p.label }</span>
				</>
			);

			const cls = classNames('flow--xs flex-align-center', { disabled: p.href === null });

			if(p.href) {
				return (
					<a className={ cls } href={ p.href } key={ p.label }>
						{ content }
					</a>
				);
			}

			return (
				<div className={ cls } title={ p.disabledTooltip } key={ p.label }>
					{ content }
				</div>
			);
		})}
	</div>
);
