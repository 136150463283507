import type { JSX } from 'preact/jsx-runtime';
import type { ChildrenProp } from '.';
import { useEffect, useErrorBoundary } from 'preact/hooks';
import { useErrorController } from '../controllers';


export const ErrorBoundary = ({ children }: ChildrenProp) => {
	const errorController = useErrorController();
	const [ error ] = useErrorBoundary();

	useEffect(() => {
		if(error) {
			errorController.handleError(error, 'component error boundary');
		}

	}, [error, errorController]);

	
	return children as JSX.Element;
};