import type { DetailedDailyTelemetry, DetailedTelemetry } from '@bearing-ctrl/common';
import type { ChartConfiguration, ChartData } from 'chart.js/auto';
import type { Dayjs } from 'dayjs';
import type { JSX } from 'preact/jsx-runtime';
import 'chartjs-adapter-dayjs-4';
import { Chart } from 'chart.js/auto';
import dayjs from 'dayjs';
import { useEffect, useState } from 'preact/hooks';
import { formatIsoDate } from '../../../../../util';
import { BlockDomUpdates } from '../../../util/no-update';


Chart.defaults.color = '#fff';
Chart.defaults.borderColor = '#444';

const TELEMETRY_PERIODS = [ 14, 28, 56, '__ALL_TIME__' ] as const;
type TelemetryPeriod = typeof TELEMETRY_PERIODS[number];
const DEFAULT_TELEMETRY_PERIOD: TelemetryPeriod = 28;
const parseTelemetryPeriod = (value: string): TelemetryPeriod => {
	if(value === '__ALL_TIME__') {
		return value;
	}

	const int = parseInt(value, 10);
	if(TELEMETRY_PERIODS.includes(int as TelemetryPeriod)) {
		return int as TelemetryPeriod;
	}

	return DEFAULT_TELEMETRY_PERIOD;
};
const getPeriodStartDate = (allTelemetry: Record<string, DetailedDailyTelemetry>, period: TelemetryPeriod): Dayjs => {
	if(typeof(period) === 'number') {
		return dayjs().subtract(period, 'days');
	}

	return dayjs(Object.keys(allTelemetry)[0]);
};


const findTelemetries = (allTelemetry: Record<string, DetailedDailyTelemetry>, period: TelemetryPeriod): DetailedDailyTelemetry[] => {
	const dailies: DetailedDailyTelemetry[] = [];

	let date = getPeriodStartDate(allTelemetry, period);
	while(date.isBefore(Date.now())) {
		const key = date.toISOString().split('T')[0];
		const daily = allTelemetry[key];
		// TODO: investigate missing data
		dailies.push(daily ?? ({ date: key, userIds: [] })),
		date = date.add(1, 'day');
	}

	return dailies;
};

const getData = (allTelemetry: Record<string, DetailedDailyTelemetry>, period: TelemetryPeriod): ChartData<'line'> => {
	const telemetry = findTelemetries(allTelemetry, period);
	const userIds = [ ...new Set(telemetry.flatMap(daily => daily.userIds)) ];

	return {
		labels: telemetry.map(daily => formatIsoDate(daily.date)),
		datasets: userIds.map(userId => ({
			label: userId.slice(-6),
			data: telemetry.map(daily => daily.userIds.includes(userId) ? 1 : 0),
			fill: true,
		})),
	};
};

const AdminUserTelemetryPlot = (p: { telemetry: DetailedTelemetry }): JSX.Element => {
	const [ root, setRoot ] = useState<HTMLCanvasElement | null>(null);
	const [ period, setPeriod ] = useState<TelemetryPeriod>(DEFAULT_TELEMETRY_PERIOD);
	const [ chart, setChart ] = useState<Chart | null>(null);

	useEffect(() => {
		if(!root) {
			return;
		}

		const config: ChartConfiguration = {
			type: 'line',
			data: getData(p.telemetry.daily, DEFAULT_TELEMETRY_PERIOD),
			options: {
				scales: {
					x: {
						type: 'timeseries',
					},
					y: {
						stacked: true,
						ticks: {
							stepSize: 1,
						},
						title: {
							display: true,
							text: 'Users',
						},
					},
				},
			},
		};

		const chart = new Chart(root, config);
		setChart(chart);

		return () => {
			setChart(null);
			chart.destroy();
		};
	}, [ root, p.telemetry ]);

	useEffect(() => {
		if(!chart) {
			return;
		}

		chart.data = getData(p.telemetry.daily, period);
		chart.update();
	}, [chart, p.telemetry, period]);

	if(!Object.keys(p.telemetry.daily).length) {
		return <p>[ no daily telemetry available ]</p>;
	}

	return (
		<div>
			<select
				value={ period }
				onChange={ ev => setPeriod(parseTelemetryPeriod(ev.currentTarget.value)) }
			>
				{ TELEMETRY_PERIODS.map(period => (
					<option value={ period } key={ period }>
						{ typeof(period) === 'number' ? `${period} days` : 'All Time'}
					</option>
				))}
			</select>
			<BlockDomUpdates>
				<canvas ref={ setRoot }/>
			</BlockDomUpdates>
		</div>
	);

};

export const AdminUserTelemetry = (p: { telemetry: DetailedTelemetry }): JSX.Element => {
	return (
		<AdminUserTelemetryPlot telemetry={ p.telemetry }/>
	);
};