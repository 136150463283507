import type { ApiClient } from '../api';
import type { AccountDetails, VerifyEmailErrorType, VerifyEmailRequest } from '@bearing-ctrl/common';
import { observable, subject } from 'ecce-preact';
import { UnauthorizedError } from '../api';


export namespace AccountControllerState {
	export type Loading = {
		kind: 'loading';
		details: null;
	};
	export type Unauthorized = {
		kind: 'unauthorized';
		details: null;
	};
	export type Ok = {
		kind: 'ok';
		details: AccountDetails;
	};
}
export type AccountControllerState = (
	| AccountControllerState.Loading
	| AccountControllerState.Unauthorized
	| AccountControllerState.Ok
);

export type AccountControllerConfig = {
	api: ApiClient;
};
@subject()
export class AccountController {
	private readonly api: ApiClient;

	private refreshPromise: Promise<void> | null = null;

	#state: AccountControllerState = { kind: 'loading', details: null };
	@observable() get state(): AccountControllerState { return this.#state; }
	private set state(value: AccountControllerState) { this.#state = value; }

	constructor({ api }: AccountControllerConfig) {
		this.api = api;
	}

	refreshDetails(): Promise<void> {
		return this.refreshPromise ??= this.doRefreshDetails();
	}

	private async doRefreshDetails(): Promise<void> {
		this.state = { kind: 'loading', details: null };
		try {
			const details = await this.api.getAccountDetails();
			this.state = { kind: 'ok', details };
		} catch(err) {
			if(err instanceof UnauthorizedError) {
				this.state = { kind: 'unauthorized', details: null };
				return;
			}
			throw err;
		} finally {
			this.refreshPromise = null;
		}
	}

	async resendVerificationEmail(): Promise<void> {
		const result = await this.api.resendVerificationEmail();
		if(!result) {
			this.refreshDetails();
		}
	}

	async verifyEmail(request: VerifyEmailRequest): Promise<'ok' | VerifyEmailErrorType> {
		const result = await this.api.verifyEmail(request);
		if(result === 'ok') {
			this.refreshDetails();
		}

		return result;
	}
}