import dayjs from 'dayjs';


export const formatCurrency = (value: number, currency='USD') => (
	new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency,
	}).format(value)
);

export const formatDate = (date: string | number | Date) => (
	dayjs(date!).format('D MMMM YYYY')
);

export const formatIsoDate = (date: string | number | Date) => (
	dayjs(date).toISOString().split('T')[0]
);