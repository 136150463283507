import type { ChildrenProp } from '../../../util';
import type { ComponentChild, RenderableProps } from 'preact';
import { Component } from 'preact';


/**
 * Prevents updating children after first render. Used to wrap DOM elements
 * which are managed by external libraries.
 *
 * https://preactjs.com/guide/v10/external-dom-mutations
 */
export class BlockDomUpdates extends Component<ChildrenProp> {
	shouldComponentUpdate() {
		return false;
	}


	render(props?: RenderableProps<ChildrenProp> | undefined): ComponentChild {
		return props?.children;
	}
}
