import type { ClassNameProp } from '../../../util';
import classNames from 'classnames';
import './branding.scss';


export const BearingLogo = ({ className }: ClassNameProp) => (
	<svg
		class={ className }
		aria-hidden="true"
		focusable="false"
		data-icon="Bearing"
		role="img"
		viewBox="0 0 1730.0518 542.99512"
		version="1.1"
		id="bearing-logo"
	>
		<defs>
			<filter id="dropshadow" height="1.3">
				<feGaussianBlur in="SourceAlpha" stdDeviation="4"/>
				<feOffset dx="0" dy="4" result="offsetblur"/>
				<feComponentTransfer>
					<feFuncA type="linear" slope="0.4"/>
				</feComponentTransfer>
				<feMerge>
					<feMergeNode/>
					<feMergeNode in="SourceGraphic"/>
				</feMerge>
			</filter>
		</defs>
		<path
			style="fill:#aaaaaa;fill-opacity:1;stroke:none;filter:url(#dropshadow)"
			d="m 462.73933,207.50003 h 33.73 c 11.01,0 18.61,-10.83 14.86,-21.18 -4.93,-13.58 -7.55,-27.98 -7.55,-42.82 0,-14.84 2.62,-29.24 7.55,-42.82 3.76,-10.349989 -3.85,-21.179988 -14.86,-21.179988 h -33.73 c -7.01,0 -13.46,4.49 -15.41,11.229999 -4.89,16.979989 -7.53,34.649989 -7.53,52.769989 0,18.12 2.64,35.79 7.54,52.76 1.94,6.74 8.39,11.24 15.4,11.24 z"
			id="wave-left-inner"
		/>
		<path
			style="fill:#aaaaaa;fill-opacity:1;stroke:none;filter:url(#dropshadow)"
			d="m 401.71933,38.840041 c 5.64,-10.62 -1.95,-23.34 -13.96,-23.34 h -35.33 c -6.27,0 -12.14,3.59 -14.74,9.31 -16.49,36.23 -25.89,76.339989 -25.89,118.689989 0,24.75 3.12,68.33 26.69,118.86 2.62,5.63 8.42,9.14 14.61,9.14 h 34.84 c 12.02,0 19.61,-12.74 13.95,-23.37 -49.78,-93.32 -16.71,-178.149989 -0.17,-209.289989 z"
			id="wave-left-outer"
		/>
		<path
			style="fill:#aaaaaa;fill-opacity:1;stroke:none;filter:url(#dropshadow)"
			d="m 925.85933,24.790041 c -2.6,-5.71 -8.46,-9.29 -14.73,-9.29 h -35.42 c -11.98,0 -19.66,12.66 -14.02,23.25 18.27,34.290001 48.42,119.419989 0.28,209.229989 -5.72,10.68 1.8,23.52 13.91,23.52 h 35.23 c 6.27,0 12.13,-3.58 14.73,-9.29 16.53,-36.23 25.96,-76.35 25.96,-118.71 0,-42.36 -9.42,-82.479989 -25.94,-118.709989 z"
			id="wave-right-outer"
		/>
		<path
			style="fill:#aaaaaa;fill-opacity:1;stroke:none;filter:url(#dropshadow)"
			d="m 800.85933,79.500042 h -33.73 c -11.01,0 -18.61,10.829999 -14.86,21.179988 4.93,13.58 7.55,27.98 7.55,42.82 0,14.84 -2.62,29.24 -7.55,42.82 -3.76,10.35 3.85,21.18 14.86,21.18 h 33.73 c 7.02,0 13.46,-4.49 15.41,-11.24 4.9,-16.97 7.53,-34.64 7.53,-52.76 0,-18.12 -2.64,-35.79 -7.54,-52.759989 -1.94,-6.749999 -8.39,-11.239999 -15.4,-11.239999 z"
			id="wave-right-inner"
		/>
		<path
			id="tower"
			style="fill:#aaaaaa;fill-opacity:1;stroke:none;filter:url(#dropshadow)"
			d="m 583.41933,335.50003 48.38,-116.19 48.38,116.19 z m 101.14,-155.88 c 7.05,-10.29 11.2,-22.71 11.2,-36.12 0,-35.35 -28.63,-63.999988 -63.96,-63.999988 -35.32,0 -63.96,28.649988 -63.96,63.999988 0,13.41 4.15,25.83 11.2,36.12 l -130.5,313.41 c -3.4,8.15 0.46,17.52 8.61,20.92 l 29.51,12.31 c 8.15,3.4 17.52,-0.46 20.91,-8.61 l 49.19,-118.15 h 150.07 l 49.2,118.15 c 3.4,8.16 12.76,12.01 20.91,8.61 l 29.51,-12.31 c 8.15,-3.4 12,-12.77 8.61,-20.92 z"
		/>
		<g
			aria-label="BEARING"
			transform="matrix(2.0658489,0,0,2.0658489,1675.9995,1448.3531)"
			style="fill:#ffffff;fill-opacity:1;stroke:none;filter:url(#dropshadow)"
		>
			<path
				d="m -785.09013,-547.99491 v 51.28087 h 30.37478 q 15.28113,0 22.59358,-6.28121 7.40619,-6.37495 7.40619,-19.4061 0,-13.1249 -7.40619,-19.31236 -7.31245,-6.2812 -22.59358,-6.2812 z m 0,-57.56207 v 42.18718 h 28.03104 q 13.8749,0 20.62485,-5.15621 6.8437,-5.24996 6.8437,-15.93738 0,-10.59367 -6.8437,-15.84363 -6.74995,-5.24996 -20.62485,-5.24996 z m -18.93735,-15.56238 h 48.37463 q 21.65609,0 33.37475,8.99993 11.71867,8.99993 11.71867,25.59356 0,12.84365 -5.99996,20.43734 -5.99995,7.5937 -17.62487,9.46868 13.96865,2.99998 21.65609,12.56241 7.78119,9.46868 7.78119,23.71857 0,18.74986 -12.7499,28.96854 -12.74991,10.21867 -36.28098,10.21867 h -50.24962 z"
				id="letter-b"
			/>
			<path
				d="m -672.30971,-621.11936 h 88.49934 v 15.93738 h -69.56198 v 41.43719 h 66.65575 v 15.93738 h -66.65575 v 50.71837 h 71.24947 v 15.93738 h -90.18683 z"
				id="letter-e"
			/>
			<path
				d="m -362.56204,-546.77617 q 6.09371,2.06249 11.81241,8.81243 5.81246,6.74995 11.62492,18.56237 l 19.2186,38.24971 h -20.34359 l -17.90612,-35.90598 q -6.93745,-14.0624 -13.4999,-18.65611 -6.4687,-4.59372 -17.71862,-4.59372 h -20.62484 v 59.15581 h -18.93736 v -139.9677 h 42.74968 q 23.99982,0 35.81223,10.03117 11.81241,10.03118 11.81241,30.28102 0,13.21866 -6.18745,21.93734 -6.09371,8.71869 -17.81237,12.09366 z m -47.43714,-58.78081 v 49.68713 h 23.81232 q 13.6874,0 20.62484,-6.2812 7.0312,-6.37496 7.0312,-18.65612 0,-12.28115 -7.0312,-18.46861 -6.93744,-6.2812 -20.62484,-6.2812 z"
				id="letter-r"
			/>
			<path
				d="m -295.53127,-621.11936 h 18.93736 v 139.9677 h -18.93736 z"
				id="letter-i"
			/>
			<path
				d="m -238.9067,-621.11936 h 25.49981 l 62.06204,117.09287 v -117.09287 h 18.37486 v 139.9677 h -25.49981 l -62.06203,-117.09287 v 117.09287 h -18.37487 z"
				id="letter-n"
			/>
			<path
				d="m 0.15399246,-501.12026 v -37.59347 H -30.783276 v -15.56238 h 49.687128 v 60.0933 q -10.9686677,7.78119 -24.1873189,11.81241 -13.2186511,3.93747 -28.2185391,3.93747 -32.812255,0 -51.374616,-19.12486 -18.468608,-19.2186 -18.468608,-53.4371 0,-34.31224 18.468608,-53.4371 18.562361,-19.21861 51.374616,-19.21861 13.687398,0 25.9685559,3.37498 12.3749075,3.37497 22.7810801,9.93743 v 20.15609 q -10.4999219,-8.90618 -22.3123336,-13.40615 -11.8124114,-4.49996 -24.8435644,-4.49996 -25.687308,0 -38.624711,14.34364 -12.843655,14.34364 -12.843655,42.74968 0,28.31229 12.843655,42.65593 12.937403,14.34365 38.624711,14.34365 10.031175,0 17.906116,-1.68749 7.8749414,-1.78124 14.15614446,-5.43746 z"
				id="letter-g"
			/>
		</g>
	</svg>
);

export type TowerLogoProps = ClassNameProp & {
	color?: 'default' | 'brand';
};
export const TowerLogo = ({ className, color = 'default' }: TowerLogoProps) => {
	return (
		<svg
			className={ classNames(className, 'tower-logo', `tower-logo--${color}`) }
			aria-hidden
			id="svg136"
			version="1.1"
			viewBox="0 0 174.2016 155"
		>
			<defs
				id="defs130"
			>
				<filter
					id="dropshadow"
					height="1.3"
				>
					<feGaussianBlur
						in="SourceAlpha"
						stdDeviation="4"
						id="feGaussianBlur10"
					/>
					<feOffset
						dx="0"
						dy="4"
						result="offsetblur"
						id="feOffset12"
					/>
					<feComponentTransfer
						id="feComponentTransfer16"
					>
						<feFuncA
							type="linear"
							slope="0.4"
							id="feFuncA14"
						/>
					</feComponentTransfer>
					<feMerge
						id="feMerge22"
					>
						<feMergeNode
							id="feMergeNode18"
						/>
						<feMergeNode
							in="SourceGraphic"
							id="feMergeNode20"
						/>
					</feMerge>
				</filter>
				<filter
					id="filter75"
					height="1.3"
				>
					<feGaussianBlur
						in="SourceAlpha"
						stdDeviation="4"
						id="feGaussianBlur61"
					/>
					<feOffset
						dx="0"
						dy="4"
						result="offsetblur"
						id="feOffset63"
					/>
					<feComponentTransfer
						id="feComponentTransfer67"
					>
						<feFuncA
							type="linear"
							slope="0.4"
							id="feFuncA65"
						/>
					</feComponentTransfer>
					<feMerge
						id="feMerge73"
					>
						<feMergeNode
							id="feMergeNode69"
						/>
						<feMergeNode
							in="SourceGraphic"
							id="feMergeNode71"
						/>
					</feMerge>
				</filter>
				<filter
					id="filter91"
					height="1.3"
				>
					<feGaussianBlur
						in="SourceAlpha"
						stdDeviation="4"
						id="feGaussianBlur77"
					/>
					<feOffset
						dx="0"
						dy="4"
						result="offsetblur"
						id="feOffset79"
					/>
					<feComponentTransfer
						id="feComponentTransfer83"
					>
						<feFuncA
							type="linear"
							slope="0.4"
							id="feFuncA81"
						/>
					</feComponentTransfer>
					<feMerge
						id="feMerge89"
					>
						<feMergeNode
							id="feMergeNode85"
						/>
						<feMergeNode
							in="SourceGraphic"
							id="feMergeNode87"
						/>
					</feMerge>
				</filter>
				<filter
					id="filter107"
					height="1.3"
				>
					<feGaussianBlur
						in="SourceAlpha"
						stdDeviation="4"
						id="feGaussianBlur93"
					/>
					<feOffset
						dx="0"
						dy="4"
						result="offsetblur"
						id="feOffset95"
					/>
					<feComponentTransfer
						id="feComponentTransfer99"
					>
						<feFuncA
							type="linear"
							slope="0.4"
							id="feFuncA97"
						/>
					</feComponentTransfer>
					<feMerge
						id="feMerge105"
					>
						<feMergeNode
							id="feMergeNode101"
						/>
						<feMergeNode
							in="SourceGraphic"
							id="feMergeNode103"
						/>
					</feMerge>
				</filter>
				<filter
					id="filter123"
					height="1.3"
				>
					<feGaussianBlur
						in="SourceAlpha"
						stdDeviation="4"
						id="feGaussianBlur109"
					/>
					<feOffset
						dx="0"
						dy="4"
						result="offsetblur"
						id="feOffset111"
					/>
					<feComponentTransfer
						id="feComponentTransfer115"
					>
						<feFuncA
							type="linear"
							slope="0.4"
							id="feFuncA113"
						/>
					</feComponentTransfer>
					<feMerge
						id="feMerge121"
					>
						<feMergeNode
							id="feMergeNode117"
						/>
						<feMergeNode
							in="SourceGraphic"
							id="feMergeNode119"
						/>
					</feMerge>
				</filter>
			</defs>
			<g
				transform="translate(-2.9997427,-1.2077167)"
				id="layer1"
			>
				<path
					transform="matrix(0.26458333,0,0,0.26458333,2.9997427,1.2077173)"
					style={ `fill:${color};fill-opacity:1;stroke:none;filter:url(#dropshadow)` }
					d="m 160.14,217.59999 h 33.73 c 11.01,0 18.61,-10.83 14.86,-21.18 -4.93,-13.58 -7.55,-27.98 -7.55,-42.82 0,-14.84 2.62,-29.24 7.55,-42.82 3.76,-10.34999 -3.85,-21.179991 -14.86,-21.179991 h -33.73 c -7.01,0 -13.46,4.49 -15.41,11.230001 -4.89,16.97999 -7.53,34.64999 -7.53,52.76999 0,18.12 2.64,35.79 7.54,52.76 1.94,6.74 8.39,11.24 15.4,11.24 z"
					id="wave-left-inner"
				/>
				<path
					transform="matrix(0.26458333,0,0,0.26458333,2.9997427,1.2077173)"
					style={ `fill:${color};fill-opacity:1;stroke:none;filter:url(#dropshadow)` }
					d="m 99.120001,48.939998 c 5.639999,-10.62 -1.95,-23.34 -13.96,-23.34 h -35.33 c -6.27,0 -12.14,3.59 -14.74,9.31 -16.49,36.23 -25.8900002,76.339992 -25.8900002,118.689992 0,24.75 3.1200002,68.33 26.6900002,118.86 2.62,5.63 8.42,9.14 14.61,9.14 h 34.84 c 12.02,0 19.609999,-12.74 13.95,-23.37 -49.78,-93.32 -16.71,-178.149992 -0.17,-209.289992 z"
					id="wave-left-outer"
				/>
				<path
					transform="matrix(0.26458333,0,0,0.26458333,2.9997427,1.2077173)"
					style={ `fill:${color};fill-opacity:1;stroke:none;filter:url(#dropshadow)` }
					d="m 623.26,34.889998 c -2.6,-5.71 -8.46,-9.29 -14.73,-9.29 h -35.42 c -11.98,0 -19.66,12.66 -14.02,23.25 18.27,34.290001 48.42,119.419992 0.28,209.229992 -5.72,10.68 1.8,23.52 13.91,23.52 h 35.23 c 6.27,0 12.13,-3.58 14.73,-9.29 16.53,-36.23 25.96,-76.35 25.96,-118.71 0,-42.36 -9.42,-82.479992 -25.94,-118.709992 z"
					id="wave-right-outer"
				/>
				<path
					transform="matrix(0.26458333,0,0,0.26458333,2.9997427,1.2077173)"
					style={ `fill:${color};fill-opacity:1;stroke:none;filter:url(#dropshadow)` }
					d="m 498.26,89.599999 h -33.73 c -11.01,0 -18.61,10.830001 -14.86,21.179991 4.93,13.58 7.55,27.98 7.55,42.82 0,14.84 -2.62,29.24 -7.55,42.82 -3.76,10.35 3.85,21.18 14.86,21.18 h 33.73 c 7.02,0 13.46,-4.49 15.41,-11.24 4.9,-16.97 7.53,-34.64 7.53,-52.76 0,-18.12 -2.64,-35.79 -7.54,-52.75999 -1.94,-6.750001 -8.39,-11.240001 -15.4,-11.240001 z"
					id="wave-right-inner"
				/>
				<path
					transform="matrix(0.26458333,0,0,0.26458333,2.9997427,1.2077173)"
					id="tower"
					style={ `fill:${color};fill-opacity:1;stroke:none;filter:url(#dropshadow)` }
					d="m 280.82,345.59999 48.38,-116.19 48.38,116.19 z m 101.14,-155.88 c 7.05,-10.29 11.2,-22.71 11.2,-36.12 0,-35.35 -28.63,-63.999991 -63.96,-63.999991 -35.32,0 -63.96,28.649991 -63.96,63.999991 0,13.41 4.15,25.83 11.2,36.12 l -130.5,313.41 c -3.4,8.15 0.46,17.52 8.61,20.92 l 29.51,12.31 c 8.15,3.4 17.52,-0.46 20.91,-8.61 l 49.19,-118.15 h 150.07 l 49.2,118.15 c 3.4,8.16 12.76,12.01 20.91,8.61 l 29.51,-12.31 c 8.15,-3.4 12,-12.77 8.61,-20.92 z"
				/>
			</g>
		</svg>
	);
};