import type { ChildrenProp, ClassNameProp, TagProp } from '../../../util';
import type { IconName } from '../atoms';
import type { JSX } from 'preact';
import { h } from 'preact';
import classNames from 'classnames';
import { Icon, Typography } from '../atoms';
import './alert.scss';


export type AlertSeverity = 'error' | 'warning' | 'success' | 'info';

export type AlertProps = TagProp & ClassNameProp & ChildrenProp & {
	innerClassName?: string;
	severity: AlertSeverity;
	icon?: IconName | JSX.Element;
	message: string | JSX.Element;
	size?: 'md' | 'lg' | 'xl';
	variant?: 'default' | 'filled';
};

export const Alert = ({ tag='div', className, innerClassName, severity, icon=severity, message, size='lg', variant='default', children }: AlertProps) => {
	return (
		h(tag, {
			className: classNames(
				'alert',
				'alert--' + severity,
				'alert--' + size,
				'alert--' + variant,
				className
			),
			children: (
				<>
					{ typeof(icon) === 'string'
						? (
							<Icon className="alert__icon" icon={ icon ?? severity } size={ size }/>
						) : (
							<div className="alert__icon">
								{ icon }
							</div>
						)
					}

					<Typography className="alert__message" size={ size } weight="bold">
						{ message }
					</Typography>
					<div className={ classNames('alert__content', innerClassName) }>
						{ children }
					</div>
				</>
			),
		})
	);
};