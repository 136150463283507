import type { AdminUserDetails } from '@bearing-ctrl/common';
import type { JSX } from 'preact/jsx-runtime';
import type { KeyValueTableRow } from '../../molecules/key-value-table';
import { useSubject } from 'ecce-preact';
import { useControllers } from '../../../../controllers';
import { useConstant } from '../../../../util';
import { Content, Divider, Panel, PanelTitle, Spinner } from '../../atoms';
import { Alert } from '../../molecules';
import { KeyValueTable } from '../../molecules/key-value-table';
import { AdminUserLastSeenDate } from '../../organisms';
import { InvoiceHistoryView } from '../dashboard/dashboard-billing/invoice-history-view';
import { AdminUserController } from './admin-user-controller';
import { AdminUserAddress, AdminUserBilling, AdminUserTelemetry, AdminUserToolbar } from './components';
import './admin-user-page.scss';


const getOverviewRows = (user: AdminUserDetails): KeyValueTableRow[] => {
	return [
		{ key: 'Version', value: user.telemetry?.version },
		{ key: 'Provider', value: user.telemetry?.dataProvider },
		{ key: 'Last Seen', value: user.lastSuccessfulValidation && <AdminUserLastSeenDate date={ user.lastSuccessfulValidation }/> },
		{ key: 'Devices', value: user.rfDevices },
	];
};

export const AdminUserPage = (p: { userId: string }): JSX.Element => {
	const controllers = useControllers();

	const controller = useConstant(() => new AdminUserController(controllers, p.userId));
	useSubject(controller);

	if(controller.error) {
		return (
			<Content width="sm">
				<Alert severity="error" message={ controller.error }/>
			</Content>
		);
	}

	if(!controller.user) {
		return <Spinner/>;
	}

	return (
		<Content width="lg">
			<Panel className="adminUserPage">
				<PanelTitle
					icon="user"
					title={ controller.user.companyName }
					headingLevel={ 1 }
				/>
				<AdminUserToolbar user={ controller.user }/>
				<Divider/>
				<section className="details">
					<KeyValueTable rows={ getOverviewRows(controller.user) }/>
					<AdminUserBilling user={ controller.user }/>
					<AdminUserAddress user={ controller.user }/>
				</section>
				<Divider/>
				<section>
					{ controller.user.telemetry && (
						<AdminUserTelemetry telemetry={ controller.user.telemetry }/>
					)}
				</section>
				<section>
					<InvoiceHistoryView invoices={ controller.user.invoices }/>
				</section>
			</Panel>
		</Content>
	);
};