import type { ChildrenProp, ClassNameProp } from '../../../util';
import type { Placement } from '@popperjs/core';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'preact/hooks';
import { usePopper } from 'react-popper';
import './popover.scss';


export type PopoverProps = ClassNameProp & ChildrenProp & {
	anchor: HTMLElement | null;
	open: boolean;
	placement: Placement;
};
export const Popover = ({ className, anchor, open, placement, children }: PopoverProps) => {
	const [ el, setEl ] = useState<HTMLDivElement | null>(null);
	const { styles, attributes } = usePopper(anchor, el, {
		placement,
	});

	const [ visible, setVisible ] = useState(false);

	const [ mouseOver, setMouseOver ] = useState(false);
	const handleMouseEnter = useCallback(() => {
		setMouseOver(true);
	}, []);
	const handleMouseLeave = useCallback(() => {
		setMouseOver(false);
	}, []);
	
	useEffect(() => {
		const shouldBeVisible = open || mouseOver;
		
		if(shouldBeVisible === visible) {
			return;
		}

		if(shouldBeVisible) {
			setVisible(true);
			return;
		}

		const timeout = setTimeout(() => {
			setVisible(false);
		}, 50);

		return () => { clearTimeout(timeout); };
	}, [mouseOver, open, visible]);
	
	if(!open && !visible) {
		return null;
	}
	
	return (
		<div
			ref={ setEl }
			className={ classNames('popover', className) }
			{ ...attributes.popper }
			style={ styles.popper }
			onMouseEnter={ handleMouseEnter }
			onMouseLeave={ handleMouseLeave }
		>
			{ children }
			<div
				{ ...attributes.arrow }
				style={ styles.arrow }
			/>
		</div>
	);
};