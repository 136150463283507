import { useAccountDetails } from '../../../../../controllers';
import { formatDate } from '../../../../../util';
import { Divider, Panel } from '../../../atoms';
import { Alert } from '../../../molecules';


export const PaymentFailedAlert = () => {
	const { billing } = useAccountDetails();

	if(billing.billingStatus !== 'failed') {
		return null;
	}

	return (
		<>
			<Panel>
				<Alert
					severity="error"
					message={ billing.failedPayment?.reason ?? 'Payment Failed' }
				>
					<div className="copy">
						{ billing.failedPayment?.reason !== 'No payment method available' && (
							<>
								Ensure funds are available and retry the payment, or update
								your payment method below.
							</>
						)}
						{ billing.failedPayment?.reason === 'No payment method available' && (
							<>
								<p>
									We were unable to take payment for your most recent invoice
									because no payment method is registered for your account.
								</p>
								<p>Choose a payment method below to retry the payment.</p>
							</>
						)}
						<p>
							If no action is taken, your license will expire on&nbsp;
							<strong>{ formatDate(billing.licenseExpiresDate!) }</strong>
						</p>
					</div>
				</Alert>
			</Panel>
			<Divider/>
		</>
	);
};