import { BearingLogo, Bold, Content, LinkButton, Panel, Typography, TowerLogo, Divider } from '../..';
import './landing.scss';


export const Landing = () => {
	return (
		<div className="landing stack--lg">
			<div className="landing__background-image" aria-hidden/>
			<Content
				tag="header"
				innerClassName="landing__header stack--lg"
				width="lg"
			>
				<BearingLogo/>
				<Typography className="landing__tagline" font="display" size="xxl" align="center" overImage>
					Frequency Management software for WISPs
				</Typography>
			</Content>

			<Content>
				<Panel className="stack--lg">
					<Typography className="stack" size="lg">
						<p><Bold>Bearing</Bold> leverages your existing systems to make Radio Frequency Management simple. Once SNMP device probes are configured to return live frequency and channel width data for your devices, <Bold>Bearing</Bold> flags up potential issues and shows spectrum overlay maps of your&nbsp;network.</p>
						<p><Bold>Bearing</Bold> will prompt you about missing data and our documentation can help you fill the&nbsp;gaps.</p>
					</Typography>

					<ul className="landing__feature-list">
						<li class="landing__feature-item">
							<TowerLogo color="brand"/>
							<span>Easily visualize your spectrum usage for each&nbsp;site</span>
						</li>
						<li class="landing__feature-item">
							<TowerLogo color="brand"/>
							<span>Identify potential interference and frequency&nbsp;overlap</span>
						</li>
						<li class="landing__feature-item">
							<TowerLogo color="brand"/>
							<span>Assist with Sector and Backhaul&nbsp;alignment</span>
						</li>
						<li class="landing__feature-item">
							<TowerLogo color="brand"/>
							<span>Co-location and multi-site spectrum&nbsp;usage</span>
						</li>
					</ul>

					<Divider/>
					<div className="landing__cta">
						<LinkButton
							className="pad-md flex-1"
							href="/login"
						>
							<Typography size="lg" align="center">
								Log in
							</Typography>
						</LinkButton>

						<LinkButton
							className="pad-md flex-1"
							variant="primary"
							href="/signup"
						>
							<Typography size="lg" align="center">
								<Bold>Sign up</Bold>
							</Typography>
						</LinkButton>
					</div>
				</Panel>
			</Content>
		</div>
	);
};