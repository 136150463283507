import { useCallback } from 'preact/hooks';
import { useAdminController, useAdminDetails } from '../../../../controllers';
import { Button, LinkButton } from '../../atoms';
import { Alert } from '../../molecules';


export const FreeagentAdmin = () => {
	const adminController = useAdminController();
	const { freeAgent: { connected, oauthUrl } } = useAdminDetails();

	const handleTestConnection = useCallback(() => {
		adminController.testFreeagentConnection()
			.then(result => {
				// eslint-disable-next-line no-alert
				alert(result ? 'Success' : 'Failure!');
				if(!result) {
					adminController.refreshData();
				}
			});
	}, [adminController]);
	
	return (
		<Alert
			severity={ connected ? 'success' : 'warning' }
			message={ connected ? 'Connected to FreeAgent' : 'Not connected to FreeAgent' }
		>
			{ !connected && (
				<LinkButton href={ oauthUrl } className="mrg-auto">
					Connect
				</LinkButton>
			)}
			{ connected && (
				<Button onClick={ handleTestConnection }>
					Test connection
				</Button>
			)}
		</Alert>
	);
};