import type { RouteProps } from '../../util';
import { route } from 'preact-router';
import { SignupForm } from '../components';
import { PanelTemplate } from '../components/templates/panel-template';


const handleSignUp = () => { route('/'); };

export const SignupRoute = (_: RouteProps) => (
	<PanelTemplate title="Sign Up">
		<SignupForm onSignUp={ handleSignUp }/>
	</PanelTemplate>
);