/* eslint-disable react/jsx-no-useless-fragment */
import { useSubject } from 'ecce-preact';
import { useAccountController } from '../../../controllers';


export type LicenseKeyProps = {
	/**
	 * String to render when no license key is available.
	 *
	 * @default "<your license key>"
	 */
	fallback?: string;
};
export const LicenseKey = ({ fallback = '<your license key>' }: LicenseKeyProps) => {
	const accountController = useAccountController();
	useSubject(accountController, 'state');

	if(accountController.state.kind !== 'ok') {
		return <>{ fallback }</>;
	}

	return <>{ accountController.state.details.licenseKey }</>;
};