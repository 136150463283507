import type { JSX } from 'preact/jsx-runtime';
import type { ChildrenProp, ClassNameProp } from '../../../util';
import classNames from 'classnames';
import './sidebar-template.scss';


export type SidebarTemplateProps = ClassNameProp & ChildrenProp & {
	sidebar: JSX.Element;
};
export const SidebarTemplate = ({ className, sidebar, children }: SidebarTemplateProps) => {

	return (
		<div className={ classNames('sidebar-template', className) }>
			<div className="sidebar-template__sidebar">
				{ sidebar }
			</div>
			<div className="sidebar-template__main">
				{ children }
			</div>
		</div>
	);
};