import type { PaymentMethodDetails } from '@bearing-ctrl/common';
import type { ClassNameProp } from '../../../../../util';
import classNames from 'classnames';
import { useSubject } from 'ecce-preact';
import { useAccountDetails, useBillingController } from '../../../../../controllers';
import { formatCurrency, formatDate } from '../../../../../util';
import { Button, LinkButton, Panel, PanelTitle, Typography } from '../../../atoms';
import { Alert } from '../../../molecules';
import { CreditCard } from './credit-card';
import './active-billing-view.scss';


type PaymentMethodViewProps = ClassNameProp & {
	paymentMethod?: PaymentMethodDetails;
};
const PaymentMethodView = ({ className, paymentMethod }: PaymentMethodViewProps) => {
	const billingController = useBillingController();
	useSubject(billingController, 'loadingStatus');
	
	return (
		<Panel className={ classNames(className, 'payment-method-view') }>
			<PanelTitle title="Payment Method" headingLevel={ 2 } icon="credit-card"/>
			<div className="payment-method-view__content">
				{ paymentMethod && (
					<CreditCard
						className="payment-method-view__card"
						brand={ paymentMethod.brand }
						lastFourDigits={ paymentMethod.lastFourDigits }
					/>
				)}
				{ !paymentMethod && (
					<div className="payment-method-view__no-payment-method">
						<Alert severity="warning" message="No payment method registered."/>
					</div>
				)}
				
				<Button
					onClick={ billingController.updatePaymentMethod }
					actionInProgress={ billingController.loadingStatus === 'payment-method' }
					disabled={ !!billingController.loadingStatus }
				>
					{ paymentMethod ? 'Change payment method' : 'Set payment method' }
					
				</Button>
			</div>
		</Panel>
	);
};

const NextPaymentView = ({ className }: ClassNameProp) => {
	const { billing } = useAccountDetails();

	if(billing.billingStatus !== 'subscribed') {
		return null;
	}
	
	return (
		<Panel className={ classNames('next-payment-view', className) }>
			<PanelTitle headingLevel={ 2 } icon='clock' title="Next payment"/>
			<div className="next-payment-view__info">
				<div>
					<Typography>Your next payment is due on:</Typography>
					<Typography size="xl" weight="bold">
						{ formatDate(billing.nextPaymentDate!) }
					</Typography>
				</div>
				<div>
					<Typography>Based on current usage, you will be charged:</Typography>
					<Typography size="xl" weight="bold">
						{ formatCurrency(billing.userCost) }
					</Typography>
				</div>
			</div>
			<LinkButton href="/billing/cancel">
				Cancel billing
			</LinkButton>
		</Panel>
	);
};

const RetryPaymentView = ({ className }: ClassNameProp) => {
	const { billing } = useAccountDetails();

	const billingController = useBillingController();
	useSubject(billingController, 'loadingStatus');

	if(billing.billingStatus !== 'failed' || !billing.paymentMethod) {
		return null;
	}
	
	return (
		<Panel className={ classNames('retry-payment-view', className) }>
			<PanelTitle icon="refresh" title="Retry Payment"/>
			<div className="retry-payment-view__info">
				{ billing.failedPayment && (
					<Typography size="lg" align="center">
						Retry payment of <strong>{ formatCurrency(billing.failedPayment.dollars) }</strong>.
					</Typography>
				) }
			</div>
			<Button
				onClick={ billingController.retryFailedPayment }
				actionInProgress={ billingController.loadingStatus === 'retry-failed-payment' }
				disabled={ !!billingController.loadingStatus }
				fullWidth
			>
				Retry payment
			</Button>
		</Panel>
	);
};

export const ActiveBillingView = () => {
	const { billing } = useAccountDetails();
	
	if(billing.billingStatus !== 'subscribed' && billing.billingStatus !== 'failed') {
		return null;
	}

	return (
		<div className="active-billing-view">
			<NextPaymentView/>
			<RetryPaymentView/>
			<PaymentMethodView
				paymentMethod={ billing.paymentMethod }
			/>
		</div>
	);
};