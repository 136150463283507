import type { VerifyEmailErrorType } from '@bearing-ctrl/common';
import { route } from 'preact-router';
import { useEffect, useState } from 'preact/hooks';
import { useAccountController } from '../../../controllers/controllers-context';
import { EmailSupportLink, LinkButton } from '../atoms';
import { Alert } from '../molecules';


type VerificationStatus = 'init' | 'verifying' | 'ok' | VerifyEmailErrorType;
export type EmailVerificationProps = {
	code: string;
};
export const EmailVerification = ({ code }: EmailVerificationProps) => {
	const accountController = useAccountController();

	const [ status, setStatus ] = useState<VerificationStatus>('init');
	
	useEffect(() => {
		if(status !== 'init') {
			return;
		}

		setStatus('verifying');
		accountController.verifyEmail({ code })
			.then(result => {
				switch(result) {
					case 'not-necessary':
						route('/');
						return;
					default:
						setStatus(result);
				}
			});
	}, [accountController, code, status]);
	
	switch(status) {
		case 'verifying':
			return null;
		case 'bad-code':
			return (
				<div className="stack">
					<Alert
						severity="error"
						message="Invalid link"
						innerClassName="copy"
						size="xl"
					/>
					<p>Please check the email and try again.</p>
					<p>
						If you are having trouble verifying your email address,
						&nbsp;<EmailSupportLink>contact support</EmailSupportLink>.
					</p>
				</div>
			);
		case 'ok':
			return (
				<div className="stack">
					<Alert
						severity="success"
						message="Email address verified!"
					/>
					<LinkButton href="/">Return to Dashboard</LinkButton>
				</div>

			);
	}
	
	return <p>{code}</p>;
};