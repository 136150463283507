import type { ChangePasswordErrorType, ChangePasswordRequest, InitResetPasswordRequest, LoginRequest, SignupRequest, SignupResponse } from '@bearing-ctrl/common';
import type { ApiClient } from '../api';
import type { AccountController } from './account-controller';
import type { ReferralController } from './referral-controller';


export type AuthControllerConfig = {
	api: ApiClient;
	accountController: AccountController;
	referralController: ReferralController;
};
export class AuthController {
	private readonly api: ApiClient;
	private readonly accountController: AccountController;
	private readonly referralController: ReferralController;

	constructor({ api, accountController, referralController }: AuthControllerConfig) {
		this.api = api;
		this.accountController = accountController;
		this.referralController = referralController;
	}

	async login(request: LoginRequest): Promise<boolean> {
		const success = await this.api.login(request);
		if(success) {
			this.accountController.refreshDetails();
		}

		return success;
	}

	async logout(): Promise<void> {
		await this.api.logout();
		window.location.reload();
	}

	async signup(request: Omit<SignupRequest, 'referredBy'>): Promise<SignupResponse> {
		const response = await this.api.signup({
			...request,
			referredBy: this.referralController.referredBy,
		});
		if(response.ok) {
			this.accountController.refreshDetails();
		}

		return response;
	}

	changePassword(request: ChangePasswordRequest): Promise<'ok' | ChangePasswordErrorType> {
		return this.api.changePassword(request);
	}

	requestPasswordReset(request: InitResetPasswordRequest): Promise<void> {
		return this.api.requestPasswordReset(request);
	}

	checkPasswordResetCode(code: string): Promise<boolean> {
		return this.api.checkPasswordResetCode(code);
	}

	resetPassword(code: string, password: string) {
		return this.api.resetPassword(code, password);
	}
}