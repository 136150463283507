import type { JSX } from 'preact/jsx-runtime';
import type { ChildrenProp, ClassNameProp } from '../../../util';
import classNames from 'classnames';
import { cloneElement } from 'preact';
import { useCallback } from 'preact/hooks';
import { Button, Divider, Typography } from '../atoms';
import './form.scss';


export type FormProps = ClassNameProp & ChildrenProp & {
	submitLabel?: string;
	onSubmit?: VoidFunction;
	disabled?: boolean;
	header?: JSX.Element;
	footer?: JSX.Element;
	secondaryActions?: JSX.Element;
};
export const Form = ({ className, submitLabel='Submit', onSubmit, children, disabled, header, footer, secondaryActions }: FormProps) => {
	const handleSubmit = useCallback<JSX.GenericEventHandler<HTMLFormElement>>(ev => {
		if(disabled) {
			return;
		}
		ev.preventDefault();
		onSubmit?.();
	}, [disabled, onSubmit]);
	
	return (
		<form
			className={ classNames('form', className) }
			onSubmit={ handleSubmit }
		>
			{ header && (
				<>
					{ header }
					<Divider size="sm"/>
				</>
			)}
			
			<div className="form__fields">
				{ children }
			</div>

			{ footer && (
				<>
					<Divider size="sm"/>
					{ footer }
				</>
			)}

			
			<Divider size="sm"/>
			<div className="form__actions">
				<div className="form__secondary-actions">
					{ secondaryActions }
				</div>
				
				<Button
					className="form__submit-button"
					type="submit"
					disabled={ disabled }
				>
					{ submitLabel }
				</Button>
			</div>

		</form>
	);
};

export type FormFieldProps = ClassNameProp & {
	id: string;
	label: string;
	input: JSX.Element;
	description?: string | JSX.Element;
};
export const FormField = ({ id, label, input, description }: FormFieldProps) => (
	<div className="form-field">
		<label className="form-field__label" for="id">
			<Typography size="lg">
				{ label }
			</Typography>
		</label>
		<div className="form-field__description">
			{ description }
		</div>
		<div className="form-field__input">
			{ cloneElement(input, { id }) }
		</div>
	</div>
);