import type { ClassNameProp } from '../../../../util';
import type { IconName } from '../../atoms';
import classNames from 'classnames';
import { useMemo } from 'preact/hooks';
import { Divider , Typography, LinkButton, Icon } from '../../atoms';
import { useAccountDetails } from '../../../../controllers';
import { DashboardTemplate } from '../../templates/dashboard-template';
import { AccountNotifications } from './account-notifications';
import './dashboard-home.scss';


export const UserDetails = () => {
	const { user } = useAccountDetails();
	
	if(!user) {
		return null;
	}
	
	return (
		<>
			<div className="user-details">
				<div className="user-details__stats">
					<span className="user-details__stats-value">{ user.sites }</span>
					<span className="user-details__stats-label">sites</span>

					<span className="user-details__stats-value">{ user.rfDevices }</span>
					<span className="user-details__stats-label">devices</span>
				</div>

				<Typography className="user-details__address">
					<Typography tag="span" weight="bold" size="lg">{user.companyName}</Typography><br/>
					<span>{ user.address },<br/></span>
					{ user.city && <span>{ user.city },<br/></span> }
					{ user.state && <span>{ user.state },<br/></span> }
					<span>{ user.zip }</span>
				</Typography>
			</div>
			<Divider/>
		</>
	);
};

export type DashboardLinkProps = ClassNameProp & {
	href: string;
	icon: IconName;
	title: string;
	description: string;
	disabledDescription?: string;
	disabled?: boolean;
};
export const DashboardLink = ({ className, href, icon, title, description, disabledDescription, disabled }: DashboardLinkProps) => {
	return (
		<LinkButton
			className={ classNames('dashboard-link-button', className) }
			innerClassName="dashboard-link-button__layout"
			href={ href }
			disabled={ disabled }
		>
			<Icon className="dashboard-link-button__icon" icon={ icon } size="xxl"/>
			<Typography className="dashboard-link-button__title" size="xl">
				{ title }
			</Typography>
			<Typography className="dashboard-link-button__description" size="sm">
				{ disabled && disabledDescription || description }
			</Typography>
		</LinkButton>
	);
};

const BillingDashboardLinkButton = () => {
	const { beta, verifiedEmail, activatedLicense } = useAccountDetails();

	const [ disabled, description ] = useMemo(() => {
		if(beta) {
			return [ true, 'Bearing is free during beta' ];
		}

		if(!verifiedEmail) {
			return [ true, 'Verify your email to enable billing' ];
		}

		if(!activatedLicense) {
			return [ true, 'Activate your license to enable billing' ];
		}
		

		return [ false, 'Manage your payment details' ];
	}, [activatedLicense, beta, verifiedEmail]);

	return (
		<DashboardLink
			href="/billing"
			icon="credit-card"
			title="Billing"
			description={ description }
			disabled={ disabled }
		/>
	);
};


export const DashboardHome = () => {
	const { licenseKey } = useAccountDetails();
	
	return (
		<DashboardTemplate innerClassName="dashboard-home" home>
			<AccountNotifications/>
			<UserDetails/>
			<div className="dashboard-home__links">
				<DashboardLink
					href="/documentation"
					icon="book"
					title="Documentation"
					description="How to install and use Bearing"
				/>
				<DashboardLink
					href="/license"
					icon="key"
					title="License"
					description={ licenseKey ? 'View your license key' : 'Verify your email to get a license key' }
					disabled={ !licenseKey }
				/>
				<BillingDashboardLinkButton/>
				<DashboardLink
					href="/account"
					icon="person"
					title="Account"
					description="Change your password"
				/>
			</div>
			<Divider/>
			<Typography size="sm">
				Review <a href="/about/terms-of-use">Terms of Use</a> or <a href="/about/end-user-license-agreement">End User Licence Agreement</a>
			</Typography>
		</DashboardTemplate>
	);
};