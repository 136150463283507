"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.adminDetailsSchema = exports.adminBuildDetails = exports.adminFreeAgentDetailsSchema = exports.adminUserDetailsSchema = exports.detailedTelemetrySchema = exports.detailedDailyTelemetrySchema = exports.adminActiveUserOverviewSchema = exports.collatedTelemetrySchema = exports.accountDetailsSchema = exports.accountBillingDetails = exports.invoiceDetailsSchema = exports.paymentStatusSchema = exports.failedPaymentDetailsSchema = exports.paymentMethodDetailsSchema = exports.paymentMethodBrandSchema = exports.BILLING_STATUSES = exports.billingStatusSchema = exports.userDetailsSchema = void 0;
const typebox_1 = require("@sinclair/typebox");
const nullable = (schema) => typebox_1.Type.Union([schema, typebox_1.Type.Null()]);
exports.userDetailsSchema = typebox_1.Type.Object({
    companyName: typebox_1.Type.String(),
    address: typebox_1.Type.String(),
    city: typebox_1.Type.Optional(typebox_1.Type.String()),
    state: typebox_1.Type.Optional(typebox_1.Type.String()),
    zip: typebox_1.Type.String(),
    country: typebox_1.Type.String(),
    sites: typebox_1.Type.Integer(),
    rfDevices: typebox_1.Type.Integer(),
});
exports.billingStatusSchema = typebox_1.Type.Union([
    typebox_1.Type.Literal('beta'),
    typebox_1.Type.Literal('trial'),
    typebox_1.Type.Literal('subscribed'),
    typebox_1.Type.Literal('cancelled'),
    typebox_1.Type.Literal('failed'),
]);
exports.BILLING_STATUSES = Object.freeze([
    'beta',
    'trial',
    'subscribed',
    'cancelled',
    'failed',
]);
exports.paymentMethodBrandSchema = typebox_1.Type.Union([
    typebox_1.Type.Literal('amex'),
    typebox_1.Type.Literal('diners'),
    typebox_1.Type.Literal('discover'),
    typebox_1.Type.Literal('jcb'),
    typebox_1.Type.Literal('mastercard'),
    typebox_1.Type.Literal('unionpay'),
    typebox_1.Type.Literal('visa'),
    typebox_1.Type.Literal('unknown'),
], { title: 'PaymentMethodBrand' });
exports.paymentMethodDetailsSchema = typebox_1.Type.Object({
    brand: exports.paymentMethodBrandSchema,
    lastFourDigits: typebox_1.Type.String(),
}, { title: 'PaymentMethodDetails' });
exports.failedPaymentDetailsSchema = typebox_1.Type.Object({
    reference: typebox_1.Type.String(),
    dollars: typebox_1.Type.Number(),
    reason: typebox_1.Type.String(),
}, { title: 'FailedPaymentDetails' });
exports.paymentStatusSchema = typebox_1.Type.Union([
    typebox_1.Type.Literal('pending'),
    typebox_1.Type.Literal('paid'),
    typebox_1.Type.Literal('failed'),
], { title: 'PaymentStatus' });
exports.invoiceDetailsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    reference: typebox_1.Type.String(),
    value: typebox_1.Type.Number(),
    currency: typebox_1.Type.String(),
    status: exports.paymentStatusSchema,
    failureReason: typebox_1.Type.Optional(typebox_1.Type.String()),
    createdDate: typebox_1.Type.String({ format: 'date' }),
    paymentDate: typebox_1.Type.String({ format: 'date' }),
}, { title: 'InvoiceDetails' });
exports.accountBillingDetails = typebox_1.Type.Object({
    stripePublicKey: typebox_1.Type.String(),
    billingStatus: exports.billingStatusSchema,
    dollarsPerDevice: typebox_1.Type.Number(),
    userCost: typebox_1.Type.Number(),
    paymentMethod: typebox_1.Type.Optional(exports.paymentMethodDetailsSchema),
    nextPaymentDate: typebox_1.Type.Optional(typebox_1.Type.String()),
    licenseExpiresDate: typebox_1.Type.Optional(typebox_1.Type.String()),
    evaluation: typebox_1.Type.Optional(typebox_1.Type.Object({
        endDate: typebox_1.Type.String(),
        firstBillingDate: typebox_1.Type.String(),
    })),
    failedPayment: typebox_1.Type.Optional(exports.failedPaymentDetailsSchema),
    invoices: typebox_1.Type.Array(exports.invoiceDetailsSchema),
}, { title: 'AccountBillingDetails' });
exports.accountDetailsSchema = typebox_1.Type.Object({
    email: typebox_1.Type.String(),
    activatedLicense: typebox_1.Type.Boolean(),
    verifiedEmail: typebox_1.Type.Boolean(),
    beta: typebox_1.Type.Boolean(),
    user: typebox_1.Type.Union([exports.userDetailsSchema, typebox_1.Type.Null()]),
    licenseKey: typebox_1.Type.Union([typebox_1.Type.String(), typebox_1.Type.Null()]),
    admin: typebox_1.Type.Boolean(),
    billing: exports.accountBillingDetails,
}, { title: 'AccountDetails' });
exports.collatedTelemetrySchema = typebox_1.Type.Partial(typebox_1.Type.Object({
    lastActiveDay: typebox_1.Type.Optional(typebox_1.Type.Object({
        activeUsers: typebox_1.Type.Number(),
        date: typebox_1.Type.String(),
    })),
    version: typebox_1.Type.String(),
    dataProvider: typebox_1.Type.String(),
    lastLogin: typebox_1.Type.Optional(typebox_1.Type.Object({
        date: typebox_1.Type.String({ format: 'date' }),
        activeUsers: typebox_1.Type.Integer(),
    })),
}), { title: 'CollatedTelemetry' });
exports.adminActiveUserOverviewSchema = typebox_1.Type.Intersect([
    typebox_1.Type.Object({
        id: typebox_1.Type.String(),
        email: typebox_1.Type.String(),
        companyName: typebox_1.Type.String(),
        address: typebox_1.Type.Optional(typebox_1.Type.String()),
        city: typebox_1.Type.Optional(typebox_1.Type.String()),
        state: typebox_1.Type.Optional(typebox_1.Type.String()),
        zip: typebox_1.Type.Optional(typebox_1.Type.String()),
        country: typebox_1.Type.Optional(typebox_1.Type.String()),
        phone: typebox_1.Type.Optional(typebox_1.Type.String()),
        rfDevices: typebox_1.Type.Optional(typebox_1.Type.Integer()),
        sites: typebox_1.Type.Optional(typebox_1.Type.Integer()),
        billingStatus: typebox_1.Type.Optional(exports.billingStatusSchema),
        evaluation: typebox_1.Type.Boolean(),
        lastSuccessfulValidation: typebox_1.Type.Optional(typebox_1.Type.String()),
        nextBillingDate: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'date' })),
        stripeCustomer: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uri' })),
        freeagentContact: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uri' })),
    }),
    exports.collatedTelemetrySchema,
], { title: 'AdminActiveUserOverview' });
exports.detailedDailyTelemetrySchema = typebox_1.Type.Object({
    date: typebox_1.Type.String(),
    userIds: typebox_1.Type.Array(typebox_1.Type.String()),
});
exports.detailedTelemetrySchema = typebox_1.Type.Object({
    version: nullable(typebox_1.Type.String()),
    dataProvider: nullable(typebox_1.Type.String()),
    daily: typebox_1.Type.Record(typebox_1.Type.String(), exports.detailedDailyTelemetrySchema),
});
exports.adminUserDetailsSchema = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    email: typebox_1.Type.String(),
    companyName: typebox_1.Type.String(),
    address: typebox_1.Type.Optional(typebox_1.Type.String()),
    city: typebox_1.Type.Optional(typebox_1.Type.String()),
    state: typebox_1.Type.Optional(typebox_1.Type.String()),
    zip: typebox_1.Type.Optional(typebox_1.Type.String()),
    country: typebox_1.Type.Optional(typebox_1.Type.String()),
    phone: typebox_1.Type.Optional(typebox_1.Type.String()),
    rfDevices: typebox_1.Type.Optional(typebox_1.Type.Integer()),
    sites: typebox_1.Type.Optional(typebox_1.Type.Integer()),
    billingStatus: typebox_1.Type.Optional(exports.billingStatusSchema),
    evaluation: typebox_1.Type.Boolean(),
    lastSuccessfulValidation: typebox_1.Type.Optional(typebox_1.Type.String()),
    nextBillingDate: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'date' })),
    cost: typebox_1.Type.Union([typebox_1.Type.Number(), typebox_1.Type.Null()]),
    freeagentContact: typebox_1.Type.Union([typebox_1.Type.String(), typebox_1.Type.Null()]),
    stripeCustomer: typebox_1.Type.Union([typebox_1.Type.String(), typebox_1.Type.Null()]),
    telemetry: nullable(exports.detailedTelemetrySchema),
    invoices: typebox_1.Type.Array(exports.invoiceDetailsSchema),
}, { title: 'AdminUserDetails' });
exports.adminFreeAgentDetailsSchema = typebox_1.Type.Object({
    oauthUrl: typebox_1.Type.String(),
    connected: typebox_1.Type.Boolean(),
}, { title: 'AdminFreeAgentDetails' });
exports.adminBuildDetails = typebox_1.Type.Object({
    version: typebox_1.Type.String(),
    commit: typebox_1.Type.String(),
});
exports.adminDetailsSchema = typebox_1.Type.Object({
    build: exports.adminBuildDetails,
    activeUsers: typebox_1.Type.Array(exports.adminActiveUserOverviewSchema),
    allUserEmailAddresses: typebox_1.Type.Array(typebox_1.Type.String()),
    pendingBetaRequestEmailAddresses: typebox_1.Type.Array(typebox_1.Type.String()),
    unactivatedUserEmailAddresses: typebox_1.Type.Array(typebox_1.Type.String()),
    freeAgent: exports.adminFreeAgentDetailsSchema,
    enableDevTools: typebox_1.Type.Boolean(),
}, { title: 'AdminDetails' });
