import type { AdminActiveUserOverview, AdminDetails, AdminUserDetails, BillingStatus } from '@bearing-ctrl/common';
import type { ApiClient } from '../api';
import type { AccountController } from './account-controller';
import { callback, observable, subject } from 'ecce-preact';


@subject()
export class AdminDevTools {
	@observable()
	targetUser: string;
	
	constructor(private readonly api: ApiClient, private readonly adminController: AdminController, accountController: AccountController) {
		if(accountController.state.kind !== 'ok') {
			throw new Error('Cannot load dev-tools for non-logged in user.');
		}

		this.targetUser = accountController.state.details.email;
	}

	private async handleResponse(response: Response) {
		if(response.ok) {
			alert('OK.'); // eslint-disable-line no-alert
			await this.adminController.refreshData();
			return;
		}

		alert(`Error: ${response.status} ${response.statusText} ${JSON.stringify(await response.json(), null, 2)}`); // eslint-disable-line no-alert
	}
	
	async fakeData(companyName: string) {
		const response = await this.api.fetchRaw('POST', '/admin/dev/fake-data', {
			targetUser: this.targetUser,
			companyName,
		});
		return this.handleResponse(response);
	}

	async setBillingStatus(billingStatus: BillingStatus) {
		const response = await this.api.fetchRaw('POST', '/admin/dev/billing-status', {
			targetUser: this.targetUser,
			billingStatus,
		});
		return this.handleResponse(response);
	}

	async runBillingCronJob(cronJobName: string) {
		const response = await this.api.fetchRaw('POST', '/admin/dev/billing-cron', { targetUser: this.targetUser, cronJobName });
		return this.handleResponse(response);
	}

	@callback
	async resetAllBillingInformation() {
		const response = await this.api.fetchRaw('DELETE', '/admin/dev/billing', { targetUser: this.targetUser });
		return this.handleResponse(response);
	}

	async setLicenseExpires(days: number) {
		const response = await this.api.fetchRaw('POST', '/admin/dev/license-expires', { targetUser: this.targetUser, days });
		return this.handleResponse(response);
	}

	async setEvaluationEnd(days: number) {
		const response = await this.api.fetchRaw('POST', '/admin/dev/evaluation-end', { targetUser: this.targetUser, days });
		return this.handleResponse(response);
	}
}

export namespace AdminState {
	export type Init = {
		kind: 'init';
		details: null;
	};
	export type Loading = {
		kind: 'loading';
		details: null;
	};
	export type Ok = {
		kind: 'ok';
		details: AdminDetails;
	};
}
export type AdminState = AdminState.Init | AdminState.Loading | AdminState.Ok;

export type AdminControllerConfig = {
	api: ApiClient;
	accountController: AccountController;
};

export type UserDetailsResult = (
	| { ok: true, user: AdminUserDetails }
	| { ok: false, error: unknown }
);

@subject()
export class AdminController {
	private readonly api: ApiClient;
	private readonly accountController: AccountController;
	
	#state: AdminState = { kind: 'init', details: null };
	@observable() get state(): AdminState { return this.#state; }
	private set state(value: AdminState) { this.#state = value; }
	
	#devTools: AdminDevTools | null = null;

	constructor({ api, accountController }: AdminControllerConfig) {
		this.api = api;
		this.accountController = accountController;
	}
	
	async init(): Promise<void> {
		this.#devTools = null;
		if(this.state.kind !== 'init' || this.accountController.state.kind !== 'ok') {
			return;
		}

		this.state = { kind: 'loading', details: null };

		const details = await this.api.getAdmin();
		if(!details) {
			window.location.href = '/';
			return;
		}

		this.state = { kind: 'ok', details };
	}

	async getUserDetails(id: string): Promise<AdminUserDetails> {
		return await this.api.fetch<AdminUserDetails>('GET', `/admin/users/${id}`);
	}

	async activateBillingFromBeta(user: AdminActiveUserOverview | AdminUserDetails): Promise<void> {
		// eslint-disable-next-line no-alert
		if(!confirm(`Activate billing for ${user.companyName} (${user.email})?`)) {
			return;
		}

		const response = await this.api.fetchRaw('POST', '/admin/activate-billing-from-beta', {
			email: user.email,
		});
		if(response.ok) {
			return this.refreshData();
		}

		const responseBody = await response.json();
		alert(`${response.status}: ${JSON.stringify(responseBody, null, 2)}`); // eslint-disable-line no-alert
	}

	get devTools() {
		if(!this.#devTools && this.state.details?.enableDevTools) {
			this.#devTools = new AdminDevTools(this.api, this, this.accountController);
		}

		return this.#devTools;
	}
	
	refreshData(): Promise<void> {
		this.state = { kind: 'init', details: null };
		return this.init();
	}

	async testFreeagentConnection(): Promise<boolean> {
		const { ok } = await this.api.fetch<{ok: boolean}>('GET', '/admin/test-freeagent-connection');
		return ok;
	}
}