import { route } from 'preact-router';
import { useEffect, useRef, useState } from 'preact/hooks';
import { useAuthController } from '../../../controllers';
import { useAutofocus } from '../../../util';
import { Bold, EmailSupportLink, Input, Typography } from '../atoms';
import { Alert, Form, FormField } from '../molecules';
import { EmailFailureDetails } from '../molecules/email-failure-details';
import { NewPasswordInputs } from '../molecules/new-password-inputs';
import { LoginForm } from './login-form';


const DESCRIPTION_ID = 'resetDescription';

const InitPasswordReset = () => {
	const authController = useAuthController();

	const [ email, setEmail ] = useState('');
	const [ working, setWorking ] = useState(false);
	const [ submitted, setSubmitted ] = useState(false);

	const emailInputRef = useRef<HTMLInputElement>(null);
	useAutofocus(emailInputRef);
	
	const handleSubmit = () => {
		setWorking(true);
		return authController.requestPasswordReset({ email })
			.then(() => {
				setSubmitted(true);
			})
			.finally(() => {
				setWorking(false);
			});
	};

	if(submitted) {
		return (
			<Alert size="lg" severity="success" message="Password reset email sent.">
				<EmailFailureDetails resendEmail={ handleSubmit }/>
			</Alert>
		);
	}
	
	return (
		<Form
			onSubmit={ handleSubmit }
			submitLabel="Request Password Reset"
			disabled={ working }
			header={ (
				<Typography id={ DESCRIPTION_ID } tag="p">
					Enter the email you use to log in to <Bold>brng.net</Bold>,
					and we will send instructions on how to reset your password.
				</Typography>
			) }
		>
			<FormField
				id="resetEmailAddress"
				label="Email Address"
				input={ (
					<Input
						ref={ emailInputRef }
						value={ email }
						onValueChange={ setEmail }
						aria-describedby={ DESCRIPTION_ID }
						autoComplete="email"
						disabled={ working }
						required
					/>
				) }
			/>
		</Form>
	);
};

type PasswordResetFormProps = {
	onSubmit: (password: string) => Promise<unknown>;
};
const PasswordResetForm = ({ onSubmit }: PasswordResetFormProps) => {
	const [ password, setPassword ] = useState('');
	const [ confirm, setConfirm ] = useState('');
	const [ working, setWorking ] = useState(false);
	
	const passwordInputRef = useRef<HTMLInputElement>(null);
	useAutofocus(passwordInputRef);
	
	const handleSubmit = () => {
		setWorking(true);
		onSubmit(password)
			.finally(() => setWorking(false));
	};
	
	return (
		<Form
			submitLabel="Reset Password"
			onSubmit={ handleSubmit }
			disabled={ working }
		>
			<p>Set your new password.</p>
			<NewPasswordInputs
				id="resetPassword"
				passwordInputRef={ passwordInputRef }
				value={ password }
				onValueChange={ setPassword }
				confirmValue={ confirm }
				onConfirmValueChange={ setConfirm }
				disabled={ working }
			/>
		</Form>
	);
};

type ApplyPasswordResetStatus = 'init' | 'checking-code' | 'valid-code' | 'bad-code' | 'success';
type ApplyPasswordResetProps = {
	code: string;
};
const ApplyPasswordReset = ({ code }: ApplyPasswordResetProps) => {
	const authController = useAuthController();
	const [ status, setStatus ] = useState<ApplyPasswordResetStatus>('init');

	useEffect(() => {
		if(status !== 'init') {
			return;
		}

		setStatus('checking-code');
		authController.checkPasswordResetCode(code)
			.then(result => {
				setStatus(result ? 'valid-code' : 'bad-code');
			});

	}, [authController, code, status]);
	
	const handleSubmit = (password: string) => (
		authController.resetPassword(code, password)
			.then(result => {
				switch(result) {
					case 'ok':
						setStatus('success');
						return;
					case 'bad-code':
						setStatus('bad-code');
						return;
				}
			})
	);
	
	switch(status) {
		case 'init':
		case 'checking-code':
			return null;
		case 'bad-code':
			return (
				<div className="copy">
					<Alert severity="error" message="Invalid link" size="lg"/>
					<p>
						Sorry, that link has expired, or is invalid. Double check the email,
						or <a href="/reset-password">try again</a>.
					</p>
					<p>
						If you are having trouble resetting your password, please <EmailSupportLink>contact support</EmailSupportLink>.
					</p>
				</div>
			);
		case 'valid-code':
			return <PasswordResetForm onSubmit={ handleSubmit }/>;
		case 'success':
			return (
				<div className="copy">
					<Alert severity="success" message="Password reset"/>
					<p>You may now log in.</p>
					<LoginForm onLogIn={ () => route('/') }/>
				</div>
			);
	}
};

export type PasswordResetProps = {
	code?: string;
};
export const PasswordReset = ({ code }: PasswordResetProps) => {
	if(!code) {
		return <InitPasswordReset/>;
	}

	return <ApplyPasswordReset code={ code }/>;
};