import { useEffect, useState } from 'preact/hooks';


const BREAKPOINTS = {
	sm: 576,
	md: 768,
	lg: 992,
	xl: 1200,
};
export type Breakpoint = keyof typeof BREAKPOINTS;

const isBreakpoint = (breakpoint: Breakpoint) => {
	const minWidth = BREAKPOINTS[breakpoint];
	return window.innerWidth > minWidth;
};

export const useBreakpoint = (breakpoint: Breakpoint): boolean => {
	const [ match, setMatch ] = useState(isBreakpoint(breakpoint));

	useEffect(() => {
		const handleResize = () => {
			setMatch(isBreakpoint(breakpoint));
		};

		handleResize();

		window.addEventListener('resize', handleResize, {  passive: true });
		return () => { window.removeEventListener('resize', handleResize); };
	}, [breakpoint]);

	return match;
};