import type { BillingStatus } from '@bearing-ctrl/common';
import type { JSX } from 'preact/jsx-runtime';
import type { ClassNameProp } from '../../../../util';
import classNames from 'classnames';


type BillingStatusUser = { billingStatus?: BillingStatus | undefined | null, evaluation: boolean };

const getBillingStatusLabel = (user: BillingStatusUser): string | undefined => {
	if(user.evaluation) {
		return 'evaluation' + (user.billingStatus === 'beta' ? ' (beta)' : '');
	}

	return user.billingStatus ?? 'trial';
};

const getBillingClassName = (user: BillingStatusUser): string | undefined => {
	if(user.evaluation) {
		return 'fg-brand';
	}

	switch(user.billingStatus) {
		case 'beta':
			return 'fg-brand';
		case 'cancelled':
			return 'fg-warn';
		case 'failed':
			return 'fg-bad';
		case 'subscribed':
			return 'fg-good';
	}
};

export const AdminUserBillingStatus = (p: ClassNameProp & { user: BillingStatusUser }): JSX.Element => (
	<span className={ classNames(getBillingClassName(p.user)) }>
		{ getBillingStatusLabel(p.user) }
	</span>
);
