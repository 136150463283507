import type { ChangePasswordErrorType } from '@bearing-ctrl/common';
import { useRef, useState } from 'preact/hooks';
import { useAuthController } from '../../../controllers';
import { useAutofocus } from '../../../util';
import { Button, Input } from '../atoms';
import { Alert, Form, FormField } from '../molecules';
import { NewPasswordInputs } from '../molecules/new-password-inputs';


type ChangePasswordResult = 'ok' | ChangePasswordErrorType | null;

type OldPasswordDescriptionProps = {
	result: ChangePasswordResult;
};
const OldPasswordDescription = ({ result }: OldPasswordDescriptionProps) => {
	switch(result) {
		case 'bad-password':
			return (
				<Alert severity="error" size="md" message="Password was incorrect"/>
			);
		default:
			return null;
	}
};

export const ChangePasswordForm = () => {
	const authController = useAuthController();

	const [ oldPassword, setOldPassword ] = useState('');
	const [ newPassword, setNewPassword ] = useState('');
	const [ newPasswordConfirm, setNewPasswordConfirm ] = useState('');

	const [ working, setWorking ] = useState(false);
	const [ result, setResult ] = useState<ChangePasswordResult>(null);

	const oldPasswordInputRef = useRef<HTMLInputElement>(null);
	useAutofocus(oldPasswordInputRef);
	
	const handleSubmit = () => {
		setWorking(true);
		setResult(null);
		authController.changePassword({ oldPassword, newPassword })
			.then(result => {
				setResult(result);
				switch(result) {
					case 'ok':
						setOldPassword('');
						setNewPassword('');
						setNewPasswordConfirm('');
						break;
					case 'bad-password':
						oldPasswordInputRef.current?.focus();
						break;
				}
			})
			.finally(() => {
				setWorking(false);
			});
	};

	if(result === 'ok') {
		return (
			<div className="stack">
				<Alert
					className="mrg-auto"
					severity="success"
					message="Password was changed"
					size="lg"
				/>
				<Button onClick={ () => setResult(null) }>OK</Button>
			</div>
		);
	}

	return (
		<Form
			onSubmit={ handleSubmit }
			submitLabel="Change Password"
		>
			<FormField
				id="changePasswordCurrent"
				label="Current Password"
				description={ <OldPasswordDescription result={ result }/> }
				input={ (
					<Input
						ref={ oldPasswordInputRef }
						value={ oldPassword }
						onValueChange={ setOldPassword }
						type="password"
						required
						disabled={ working }
					/>
				) }
			/>
			<NewPasswordInputs
				id="changePasswordNew"
				value={ newPassword }
				onValueChange={ setNewPassword }
				confirmValue={ newPasswordConfirm }
				onConfirmValueChange={ setNewPasswordConfirm }
				disabled={ working }
			/>
		</Form>
	);
};