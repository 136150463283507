import { useSubject } from 'ecce-preact';
import { route } from 'preact-router';
import { useAccountDetails, useBillingController } from '../../../../controllers';
import { formatDate } from '../../../../util';
import { Button, Divider, Typography } from '../../atoms';
import { DashboardTemplate } from '../../templates/dashboard-template';


export const DashboardCancelBilling = () => {
	const { billing } = useAccountDetails();
	const billingController = useBillingController();

	useSubject(billingController, 'loadingStatus');
	
	const handleCancelClick = () => {
		billingController.cancelBilling()
			.then(() => {
				route('/', true);
			});
	};
	
	return (
		<DashboardTemplate title="Cancel Billing">
			<Typography>
				If you proceed, no further payments will be taken.
			</Typography>
			<Typography>
				Your license will expire on {formatDate(billing.licenseExpiresDate!)}.
			</Typography>

			<Divider/>

			<Button
				onClick={ handleCancelClick }
				actionInProgress={ billingController.loadingStatus === 'activate-billing' }
				variant="danger"
				fullWidth
			>
				Cancel Billing
			</Button>
		</DashboardTemplate>
	);
};