import type { ChildrenProp, ClassNameProp } from '../../../util';


export type ExternalLinkProps = ClassNameProp & ChildrenProp & {
	href: string;
};

export const ExternalLink = ({ className, href, children }: ExternalLinkProps) => (
	<a className={ className } href={ href } target="_blank" rel="noopener noreferrer">
		{ children }
	</a>
);