import type { RouteProps } from '../../util';
import { about, documentation } from '../../documentation';
import { DocumentationPage } from '../components/pages/documentation';


export type DocRoutesProps = RouteProps & {
	matches?: { subPath?: string };
};
export const AboutRoutes = ({ matches }: DocRoutesProps) => {
	return <DocumentationPage documentation={ about } currentPath={ matches?.subPath } basePath="/about/"/>;
};
export const DocumentationRoutes = ({ matches }: DocRoutesProps) => (
	<DocumentationPage documentation={ documentation } currentPath={ matches?.subPath } basePath="/documentation/"/>
);