import { useSubject } from 'ecce-preact';
import { useAccountDetails, useBillingController } from '../../../../../controllers';
import { formatCurrency, formatDate } from '../../../../../util';
import { Bold, Button, Divider, Panel, PanelTitle, Typography } from '../../../atoms';


export const ActivateBillingView = () => {
	const { billing, user } = useAccountDetails();
	const billingController = useBillingController();

	useSubject(billingController, 'loadingStatus');
	
	switch(billing.billingStatus) {
		case 'beta':
			return <p>Bearing is free during beta.</p>;
		case 'failed':
		case 'subscribed':
			return null;
	}

	return (
		<>
			{ billing.billingStatus === 'trial' && (
				<Panel tag="section" className="copy">
					<p>
						You will be charged&nbsp;
						<Bold>{formatCurrency(billing.dollarsPerDevice)}</Bold>
						&nbsp;per device per month.
						You are currently using <Bold>{ user?.rfDevices }</Bold> devices,
						so your initial payment will be <Bold>{ formatCurrency(billing.userCost) }</Bold>.
					</p>

					{ billing.evaluation && (
						<p>
							Your first payment will be taken on <Bold>{ formatDate(billing.evaluation.firstBillingDate) }</Bold>,
							after your evaluation period ends.
						</p>
					) }

					<Divider/>
					<Typography>
						You may wish to review the&nbsp;<Bold>Bearing</Bold>&nbsp;<a href="/about/end-user-licence-agreement">End User Licence Agreement</a> before proceeding.
					</Typography>
					<Divider/>

					<Button
						onClick={ billingController.activateBilling }
						fullWidth
						disabled={ !!billingController.loadingStatus }
						actionInProgress={ billingController.loadingStatus === 'activate-billing' }
					>
						<Typography size="lg">
							{ !billing.evaluation && `Pay ${formatCurrency(billing.userCost)} & ` }
							Activate Billing
						</Typography>
					</Button>
				</Panel>
			)}
			
			{ billing.billingStatus === 'cancelled' && (
				<Panel tag="section">
					<PanelTitle icon="credit-card" title="Reactivate Billing"/>
					<Typography>
						You can reactivate billing at any time.
						Billing will resume as usual on <strong>{ formatDate(billing.nextPaymentDate!) }</strong>.
					</Typography>
					<Divider/>
					<Button
						fullWidth
						onClick={ billingController.activateBilling }
						disabled={ !!billingController.loadingStatus }
						actionInProgress={ billingController.loadingStatus === 'activate-billing' }
					>
						Reactivate billing
					</Button>
				</Panel>
			)}
		</>
	);
};