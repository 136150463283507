import type { Ref } from 'preact/hooks';
import { useLayoutEffect , useMemo } from 'preact/hooks';


export type Autofocusable = HTMLElement & { focus: VoidFunction };
export type Autofocus = {
	refocus: () => void;
};
export const useAutofocus = <T extends Autofocusable>(ref: Ref<T>): Autofocus => {
	useLayoutEffect(() => {
		ref.current?.focus();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return useMemo(() => ({
		refocus: () => ref.current?.focus(),
	}), [ref]);
};