import { useAdminDetails } from '../../../controllers';
import { Content, Divider, Heading, Panel, Typography } from '../atoms';
import { ActiveUsersView } from '../organisms';
import { AdminDevToolsView } from '../organisms/admin/admin-dev-tools';
import { AdminSection } from '../organisms/admin/admin-section';
import { FreeagentAdmin } from '../organisms/admin/freeagent-admin';
import './admin-page.scss';


const AdminHeader = () => {
	const { build } = useAdminDetails();

	return (
		<header>
			<div className="flex-between flex-align-end">
				<Heading level={ 1 }>Admin</Heading>
				<Typography className="mrg-t-auto" size="sm" secondary>
					v{build.version} ({build.commit})
				</Typography>
			</div>
			<Divider size="md"/>
		</header>
	);
};

export const AdminPage = () => {
	return (
		<Content width="full">
			<Panel className="admin-page stack">
				<AdminHeader/>

				<div className="admin-page__content">
					<AdminSection title="FreeAgent" icon="freeagent">
						<FreeagentAdmin/>
					</AdminSection>

					<AdminSection title="Users" icon="user" innerClassName="admin-page__user-table">
						<ActiveUsersView/>
					</AdminSection>
				
					<AdminDevToolsView/>
				</div>
			</Panel>
		</Content>
	);
};