import type { ChildrenProp, ClassNameProp } from '../../../util';
import { Content, Divider, Heading, Panel } from '../atoms';


export type PanelTemplateProps = ClassNameProp & ChildrenProp & {
	title: string;
	innerClassName?: string;
};
export const PanelTemplate = ({ className, innerClassName, title, children }: PanelTemplateProps) => (
	<Content innerClassName="mrg-auto" width="xs">
		<Panel className={ innerClassName }>
			<Heading level={ 1 }>{ title }</Heading>
			<Divider size="md"/>
			<div className={ className }>
				{ children }
			</div>
		</Panel>
	</Content>
);