import type { JSX } from 'preact/jsx-runtime';
import { useEffect, useState } from 'preact/hooks';
import DomPurify from 'dompurify';


type HtmlProp = JSX.DOMAttributes<EventTarget>['dangerouslySetInnerHTML'];

export const ReleaseNotes = () => {
	const [ html, setHtml ] = useState<HtmlProp | null>(null);

	useEffect(() => {
		fetch('/RELEASE.html')
			.then(res => res.ok ? res.text() : '<p>Error fetching release notes.</p>')
			.then(html => setHtml({ __html: DomPurify.sanitize(html) }));
	}, []);

	if(!html) {
		return null;
	}

	return (
		<div dangerouslySetInnerHTML={ html }/>
	);
};