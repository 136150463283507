import type { PaymentMethodDetails } from '@bearing-ctrl/common';
import type { ClassNameProp } from '../../../../../../util';
import classNames from 'classnames';
import { Typography } from '../../../../atoms';
import { CardLogo } from './card-logo';
import './credit-card.scss';


export type CreditCardViewProps = ClassNameProp & PaymentMethodDetails;
export const CreditCard = ({ className, brand, lastFourDigits }: CreditCardViewProps) => (
	<div className={ classNames('credit-card', className) }>
		<CardLogo className="credit-card__logo" brand={ brand }/>
		<Typography font="mono" size="sm">
			{ brand === 'amex' ? '**** ****** *' : '**** **** **** ' }
			{ lastFourDigits }
		</Typography>
	</div>
);