import type { ChildrenProp } from '../../util';
import type { RouterOnChangeArgs } from 'preact-router';
import { callback, observable, subject } from 'ecce-preact';
import { createContext } from 'preact';
import { useContext } from 'preact/hooks';
import { NullContextError } from '../../util';


export type RouteChangeEvent = { url: string, prevUrl?: string };
export type RouteChangeListener = (ev: RouteChangeEvent) => void;

@subject()
export class RouteController {
	#url!: string;
	@observable() get url(): string { return this.#url; }
	private set url(value: string) { this.#url = value; }
	
	private listeners = new Set<RouteChangeListener>();

	@callback
	handleRouteChange(ev: RouterOnChangeArgs) {
		this.url = ev.url;

		const event: RouteChangeEvent = {
			url: ev.url,
			prevUrl: ev.previous,
		};
		this.listeners.forEach(listener => listener(event));
	}

	onRouteChange(listener: RouteChangeListener): VoidFunction {
		this.listeners.add(listener);
		return () => { this.listeners.delete(listener); };
	}
}


const routeControllerContext = createContext<RouteController | null>(null);

export type RouteControllerProviderProps = ChildrenProp & {
	value: RouteController;
};
export const RouteControllerProvider = ({ value, children }: RouteControllerProviderProps) => {
	return (
		<routeControllerContext.Provider value={ value }>
			{ children }
		</routeControllerContext.Provider>
	);
};

export const useRouteController = () => {
	const ctx = useContext(routeControllerContext);
	if(!ctx) {
		throw new NullContextError('routeControllerContext', 'RouteControllerProvider');
	}

	return ctx;
};