import { Bold, Typography } from '../../atoms';
import { Copyable } from '../../molecules/copyable';
import { LicenseKey } from '../../organisms/license-key';
import { DashboardTemplate } from '../../templates/dashboard-template';


export const DashboardLicense = () => (
	<DashboardTemplate title="License">
		<div className="copy">
			<p>This is your license key. You will need it to activate your <Bold>Bearing</Bold> installation.</p>
			<Copyable>
				<Typography tag="pre" size="lg" align="center">
					<LicenseKey/>
				</Typography>
			</Copyable>
		</div>
	</DashboardTemplate>
);