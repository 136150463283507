import { useAccountDetails } from '../../../../../controllers';
import { DashboardTemplate } from '../../../templates/dashboard-template';
import { ActivateBillingView } from './activate-billing-view';
import { ActiveBillingView } from './active-billing-view';
import { InvoiceHistoryView } from './invoice-history-view';
import { PaymentFailedAlert } from './payment-failed-alert';


export const DashboardBilling = () => {
	return (
		<DashboardTemplate title="Billing">
			<PaymentFailedAlert/>
			<ActivateBillingView/>
			<ActiveBillingView/>
			<InvoiceHistoryView invoices={ useAccountDetails().billing.invoices }/>
		</DashboardTemplate>
	);
};