"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updatePaymentMethodSetupResultSchema = exports.activateBillingSetupResultSchema = exports.activateBillingSetupResultWithoutCheckout = exports.activateBillingSetupResultWithCheckout = exports.freeagentTokenResultSchema = exports.loginRequestSchema = exports.resetPasswordRequestSchema = exports.initResetPasswordRequestSchema = exports.changePasswordRequestSchema = exports.verifyEmailRequestSchema = exports.signupResponseOkSchema = exports.signupResponseErrorSchema = exports.signupErrorTypeSchema = exports.invalidPasswordErrorTypeSchema = exports.signupRequestSchema = void 0;
const typebox_1 = require("@sinclair/typebox");
exports.signupRequestSchema = typebox_1.Type.Object({
    email: typebox_1.Type.String(),
    password: typebox_1.Type.String(),
    acceptTou: typebox_1.Type.Boolean(),
    acceptEula: typebox_1.Type.Boolean(),
    referredBy: typebox_1.Type.Optional(typebox_1.Type.String()),
});
exports.invalidPasswordErrorTypeSchema = typebox_1.Type.Union([
    typebox_1.Type.Literal('password-short'),
    typebox_1.Type.Literal('password-long'),
]);
exports.signupErrorTypeSchema = typebox_1.Type.Union([
    exports.invalidPasswordErrorTypeSchema,
    typebox_1.Type.Literal('email-invalid'),
    typebox_1.Type.Literal('email-exists'),
    typebox_1.Type.Literal('email-long'),
    typebox_1.Type.Literal('email-free'),
    typebox_1.Type.Literal('eula'),
    typebox_1.Type.Literal('tou'),
]);
exports.signupResponseErrorSchema = typebox_1.Type.Object({
    ok: typebox_1.Type.Literal(false),
    errors: typebox_1.Type.Array(exports.signupErrorTypeSchema),
});
exports.signupResponseOkSchema = typebox_1.Type.Object({
    ok: typebox_1.Type.Literal(true),
});
exports.verifyEmailRequestSchema = typebox_1.Type.Object({
    code: typebox_1.Type.String(),
});
exports.changePasswordRequestSchema = typebox_1.Type.Object({
    oldPassword: typebox_1.Type.String(),
    newPassword: typebox_1.Type.String(),
});
exports.initResetPasswordRequestSchema = typebox_1.Type.Object({
    email: typebox_1.Type.String(),
});
exports.resetPasswordRequestSchema = typebox_1.Type.Object({
    password: typebox_1.Type.String(),
});
exports.loginRequestSchema = typebox_1.Type.Object({
    email: typebox_1.Type.String(),
    password: typebox_1.Type.String(),
}, { title: 'PostSessionRequest' });
exports.freeagentTokenResultSchema = typebox_1.Type.Union([
    typebox_1.Type.Object({
        ok: typebox_1.Type.Literal(true),
    }),
    typebox_1.Type.Object({
        ok: typebox_1.Type.Literal(false),
        statusCode: typebox_1.Type.Integer(),
        body: typebox_1.Type.String(),
    }),
], { title: 'FreeagentTokenResult' });
exports.activateBillingSetupResultWithCheckout = typebox_1.Type.Object({
    requiresCheckout: typebox_1.Type.Literal(true),
    checkoutSessionId: typebox_1.Type.String(),
}, { title: 'ActivateBillingSetupResult.WithCheckout' });
exports.activateBillingSetupResultWithoutCheckout = typebox_1.Type.Object({
    requiresCheckout: typebox_1.Type.Literal(false),
}, { title: 'ActivateBillingSetupResult.WithoutCheckout' });
exports.activateBillingSetupResultSchema = typebox_1.Type.Union([
    exports.activateBillingSetupResultWithCheckout,
    exports.activateBillingSetupResultWithoutCheckout,
], { title: 'ActivateBillingSetupResult' });
exports.updatePaymentMethodSetupResultSchema = typebox_1.Type.Object({
    checkoutSessionId: typebox_1.Type.String(),
}, { title: 'UpdatePaymentMethodSetupResult' });
