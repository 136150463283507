import type { JSX } from 'preact/jsx-runtime';
import type { ClassNameProp } from '../../../util';
import { useEffect, useState } from 'preact/hooks';
import { EmailSupportLink } from '../atoms';
import { Alert } from './alert';


export type EmailFailureDetailsProps = ClassNameProp & {
	resendEmail: () => Promise<unknown>;
};

export const ResendEmailLink = ({ resendEmail }: EmailFailureDetailsProps) => {
	const [ sent, setSent ] = useState(false);

	const handleClick: JSX.GenericEventHandler<HTMLAnchorElement> = (ev) => {
		ev.preventDefault();
		resendEmail()
			.then(() => {
				setSent(true);
			});
	};

	useEffect(() => {
		if(!sent) {
			return;
		}

		const timeout = setTimeout(() => {
			setSent(false);
		}, 2 * 1000);

		return () => { clearTimeout(timeout); };
	}, [sent]);
	
	if(sent) {
		return <Alert severity="success" size="md" message="Email resent."/>;
	}

	return (
		<>
			<a
				href="#resend"
				onClick={ handleClick }
				data-native
			>
				Resend the email
			</a>.
		</>
	);
};


export const EmailFailureDetails = ({ className, resendEmail }: EmailFailureDetailsProps) => (
	<details className={ className }>
		<summary>Didn't receive an email from us?</summary>
		<div className="copy">
			<ol>
				<li>Check your spam or junk folder.</li>
				<li><ResendEmailLink resendEmail={ resendEmail }/></li>
				<li><EmailSupportLink>Contact support</EmailSupportLink>.</li>
			</ol>
		</div>
	</details>
);