import type { JSX } from 'preact';
import type { ClassNameProp } from '../../../util';
import classNames from 'classnames';
import { isNotNullish , isNullish } from '../../../util';
import './key-value-table.scss';


export type KeyValueTableRow = { key: string, value: string | number | JSX.Element | undefined | null, };
export type KeyValueTableProps = ClassNameProp & {
	rows: readonly (KeyValueTableRow | undefined | null)[];
};

const NoData = () => (
	<div className="noData">
		[ no data ]
	</div>
);

export const KeyValueTable = (p: KeyValueTableProps): JSX.Element => {
	return (
		<table className={ classNames('keyValueTable', p.className) }>
			<tbody>
				{ p.rows.filter(isNotNullish).map(({ key, value }) => (
					<tr key={ key }>
						<th scope="row">
							{ key }
						</th>
						<td>
							{ isNullish(value) ? <NoData/> : value }
						</td>
					</tr>
				)) }
			</tbody>
		</table>
	);
};
