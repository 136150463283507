import { useSubject } from 'ecce-preact';
import { useAccountController } from '../../../controllers';
import { Spinner } from '../atoms';
import './account-loading-spinner.scss';


export const AccountLoadingSpinner = () => {
	const accountController = useAccountController();
	useSubject(accountController, 'state');

	if(accountController.state.kind !== 'loading') {
		return null;
	}

	return (
		<Spinner className="account-loading-spinner" size="lg"/>
	);
};