import type { ChildrenProp, ClassNameProp, HtmlTag } from '../../../util';
import classNames from 'classnames';
import { h } from 'preact';
import './content.scss';


export type ContentProps = ClassNameProp & ChildrenProp & {
	tag?: HtmlTag;
	innerTag?: HtmlTag;
	innerClassName?: string;
	width?: 'xs' | 'sm' | 'md' | 'lg' | 'full'
};
export const Content = ({ tag = 'div', className, innerTag = 'div', innerClassName: innerClassName, width = 'md', children }: ContentProps) => {
	return h(tag, {
		className: classNames('content', className),
		children: (
			h(innerTag, {
				className: classNames('content__inner', `content__inner--${width}`, innerClassName),
				children,
			})
		),
	});
};