import type { JSX } from 'preact/jsx-runtime';
import type { ClassNameProp } from '../../../util';
import classNames from 'classnames';
import './table.scss';
import { Typography } from './typography';


type TableCellConfig = {
	value: string | number | JSX.Element;
	tooltip?: string;
	className?: string;
};
export type TableCell = string | number | JSX.Element | TableCellConfig;
export type TableData<TColumn extends string> = {
	columns: TColumn[];
	rows: Record<TColumn, TableCell>[];
};

const getTableCellConfig = (cell: TableCell): TableCellConfig => {
	switch(typeof(cell)) {
		case 'object':
			if('value' in cell) {
				return cell;
			}
			// Fallthrough
		default:
			return { value: cell };
	}
};

export type TableProps<TColumn extends string> = ClassNameProp & {
	data: TableData<TColumn>;
	hide?: TColumn[];
};
export const Table = <TColumn extends string>({ className, data, hide }: TableProps<TColumn>) => {
	return (
		<table className={ classNames('table', className) }>
			<thead>
				<tr>
					{ data.columns.map(column => {
						if(hide?.includes(column)) {
							return null;
						}

						return (
							<th scope="col" key={ column }>
								<Typography weight="bold">{column}</Typography>
							</th>
						);
					})}
				</tr>
			</thead>

			<tbody>
				{ data.rows.map((row, index) => (
					<tr key={ index }>
						{ data.columns.map(column => {
							if(hide?.includes(column)) {
								return null;
							}
							
							const cell = getTableCellConfig(row[column]);
							return (
								<Typography
									tag="td"
									className={ cell.className }
									font={ typeof(cell.value) === 'number' ? 'mono' : 'sans' }
									tooltip={ cell.tooltip }
									key={ column + index }
								>
									{ cell.value }
								</Typography>
							);
						})}
					</tr>
				))}
			</tbody>

		</table>
	);
};