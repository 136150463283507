import { useRef } from 'preact/hooks';

/*
	Preact version of https://github.com/Andarist/use-constant.
*/

type ResultBox<T> = { v: T };

export const useConstant = <T> (fn: () => T): T => {
	const ref = useRef<ResultBox<T>>();

	if(!ref.current) {
		ref.current = { v: fn() };
	}

	return ref.current.v;
};