import type { ChildrenProp, ClassNameProp } from '../../../util';
import type { Placement } from '@popperjs/core';
import { useCallback, useState } from 'preact/hooks';
import classNames from 'classnames';
import { Icon, Popover } from '../atoms';
import './info-icon.scss';


export type InfoIconProps = ClassNameProp & ChildrenProp & {
	popoverClassName?: string;
	placement: Placement;
};
export const InfoIcon = ({ className, popoverClassName, placement, children }: InfoIconProps) => {
	const [ anchor, setAnchor ] = useState<HTMLElement | null>(null);
	const [ open, setOpen ] = useState(false);

	const handleMouseEnter = useCallback(() => {
		setOpen(true);
	}, []);

	const handleMouseLeave = useCallback(() => {
		setOpen(false);
	}, []);
	
	return (
		<>
			<span
				className={ classNames('info-icon', className) }
				ref={ setAnchor }
				onMouseEnter={ handleMouseEnter }
				onMouseEnterCapture={ handleMouseEnter }
				onMouseLeave={ handleMouseLeave }
				onMouseLeaveCapture={ handleMouseLeave }
			>
				<Icon icon="info" className="fg-brand"/>
			</span>
			<Popover
				className={ popoverClassName }
				anchor={ anchor }
				placement={ placement }
				open={ open }
			>
				{ children }
			</Popover>
		</>
	);
};