import type { AdminUserDetails } from '@bearing-ctrl/common';
import type { Controllers } from '../../../../controllers';
import { observable, subject } from 'ecce-preact';


@subject()
export class AdminUserController {
	readonly #controllers: Controllers;
	readonly #userId: string;

	#user: AdminUserDetails | null = null;
	get user(): AdminUserDetails | null { return this.#user; }
	@observable() private set user(value: AdminUserDetails | null) { this.#user = value; }

	#error: string | null = null;
	get error(): string | null { return this.#error; }
	@observable() private set error(value: string | null) { this.#error = value; }

	constructor(controllers: Controllers, userId: string) {
		this.#controllers = controllers;
		this.#userId = userId;

		this.#init();
	}

	async #init() {
		try {
			this.user = await this.#controllers.adminController.getUserDetails(this.#userId);
		} catch(err) {
			this.error = err instanceof Error ? err.message : String(err);
		}
	}
}