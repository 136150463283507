import type { ChildrenProp, ClassNameProp, TagProp } from '../../../util';
import { useCallback, useEffect, useRef, useState } from 'preact/hooks';
import classNames from 'classnames';
import { Button } from '../atoms';
import './copyable.scss';


const clipboard = window.navigator?.clipboard;

export type CopyableProps = TagProp & ClassNameProp & ChildrenProp;

export const Copyable = ({ className, children }: CopyableProps) => {
	const [ copied, setCopied ] = useState(false);
	const copyableContentRef = useRef<HTMLDivElement>(null);

	const handleClick = useCallback(() => {
		if(!clipboard) {
			return;
		}

		const copyableContentEl = copyableContentRef.current;
		if(!copyableContentEl) {
			return;
		}

		const copyableText = copyableContentEl.innerText;
		clipboard.writeText(copyableText)
			.then(() => setCopied(true));
	}, []);
	
	useEffect(() => {
		if(!copied) {
			return;
		}

		const timeout = setTimeout(() => {
			setCopied(false);
		}, 1500);

		return () => {
			clearTimeout(timeout);
			setCopied(false);
		};
	}, [copied]);

	return (
		<div className={ classNames('copyable', className) }>
			<div ref={ copyableContentRef }>
				{ children }
			</div>
			{ clipboard && (
				<Button
					className="copyable__button"
					icon={ copied ? 'success' : 'copy' }
					onClick={ handleClick }
				>
					{ copied ? 'Copied' : 'Copy' }
				</Button>
			)}
		</div>
	);
};