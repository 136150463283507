import type { ClassNameProp } from '../../../util';
import { useRef, useState } from 'preact/hooks';
import { useAuthController } from '../../../controllers/controllers-context';
import { useAutofocus } from '../../../util';
import { Input, Typography } from '../atoms';
import { Alert, Form, FormField } from '../molecules';


export type LoginFormProps = ClassNameProp & {
	onLogIn?: VoidFunction;
};
export const LoginForm = ({ className, onLogIn }: LoginFormProps) => {
	const auth = useAuthController();

	const [ working, setWorking ] = useState(false);
	const [ failed, setFailed ] = useState(false);

	const [ email, setEmail ] = useState('');
	const [ password, setPassword ] = useState('');
	
	const emailInputRef = useRef<HTMLInputElement>(null);
	const { refocus } = useAutofocus(emailInputRef);

	const handleSubmit = () => {
		if(working) {
			return;
		}

		setWorking(true);
		auth.login({ email, password })
			.then(result => {
				if(result) {
					onLogIn?.();
					return;
				}

				setWorking(false);
				setFailed(!result);
				setTimeout(() => {
					refocus();
				});
			});
	};
	
	return (
		<Form
			className={ className }
			submitLabel="Log In"
			onSubmit={ handleSubmit }
			disabled={ working }
			secondaryActions={ (
				<Typography size="sm" secondary>
					<a href="/reset-password">Forgotten password?</a>
				</Typography>
			) }
		>
			{ failed && (
				<Alert
					severity="error"
					message="Email address or password was incorrect."
					size="md"
				/>
			)}
			
			<FormField
				id="loginEmail"
				label="Email Address"
				input={ (
					<Input
						ref={ emailInputRef }
						type="email"
						autoComplete="email"
						autocapitalize="off"
						value={ email }
						onValueChange={ setEmail }
						required
						disabled={ working }
					/>
				) }
			/>

			<FormField
				id="loginPassword"
				label="Password"
				input={ (
					<Input
						type="password"
						autoComplete="current-password"
						autoCapitalize="off"
						value={ password }
						onValueChange={ setPassword }
						required
						disabled={ working }
					/>
				) }
			/>

		</Form>
	);
};