import type { ApiClient } from '../api';
import type { AccountController } from './account-controller';
import type { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { callback, observable, subject } from 'ecce-preact';
import { STRIPE_API_VERSION } from '@bearing-ctrl/common';
import { timeout } from '../util';


export type BillingControllerConfig = {
	api: ApiClient;
	accountController: AccountController;
};

export type BillingControllerLoadingStatus = (
	| false
	| 'activate-billing'
	| 'payment-method'
	| 'retry-failed-payment'
	| 'cancel-billing'
);

@subject()
export class BillingController {
	private readonly api: ApiClient;
	private readonly accountController: AccountController;

	#activateBillingLoading = false;
	@observable() get activateBillingLoading(): boolean { return this.#activateBillingLoading; }
	private set activateBillingLoading(value: boolean) { this.#activateBillingLoading = value; }
	
	#loadingStatus: BillingControllerLoadingStatus = false;
	@observable() get loadingStatus(): BillingControllerLoadingStatus { return this.#loadingStatus; }
	private set loadingStatus(value: BillingControllerLoadingStatus) { this.#loadingStatus = value; }
	
	#billingLoading = false;
	@observable() get billingLoading(): boolean { return this.#billingLoading; }
	private set billingLoading(value: boolean) { this.#billingLoading = value; }
	
	#paymentMethodLoading = false;
	@observable() get paymentMethodLoading(): boolean { return this.#paymentMethodLoading; }
	private set paymentMethodLoading(value: boolean) { this.#paymentMethodLoading = value; }

	
	#stripe: Stripe | null = null;
	
	constructor({ api, accountController }: BillingControllerConfig) {
		this.api = api;
		this.accountController = accountController;
	}

	private async getStripeClient(): Promise<Stripe> {
		if(this.accountController.state.kind !== 'ok') {
			this.#stripe = null;
			throw new Error(`Cannot get stripe controller in account state "${this.accountController.state.kind}"`);
		}

		if(!this.#stripe) {
			const { stripePublicKey } = this.accountController.state.details.billing;
			this.#stripe = await loadStripe(stripePublicKey, {
				apiVersion: STRIPE_API_VERSION,
			});
			
			if(!this.#stripe) {
				throw new Error('loadStripe() resolved to null');
			}
		}

		return this.#stripe;
	}
	
	@callback
	async activateBilling() {
		this.loadingStatus = 'activate-billing';
		try {
			const result = await this.api.activateBilling();

			if(!result.requiresCheckout) {
				await this.accountController.refreshDetails();
				return;
			}
	
			const stripe = await this.getStripeClient();
			await stripe.redirectToCheckout({
				sessionId: result.checkoutSessionId,
			});
		} finally {
			this.loadingStatus = false;
		}
	}

	@callback
	async updatePaymentMethod() {
		this.loadingStatus = 'payment-method';
		try {
			const result = await this.api.updatePaymentMethod();

			const stripe = await this.getStripeClient();
			await stripe.redirectToCheckout({ sessionId: result.checkoutSessionId });
		} finally {
			this.loadingStatus = false;
		}
	}

	@callback
	async cancelBilling() {
		this.loadingStatus = 'cancel-billing';
		try {
			await this.api.cancelBilling();
			await this.accountController.refreshDetails();
		} finally {
			this.loadingStatus = false;
		}
	}

	@callback
	async retryFailedPayment() {
		this.loadingStatus = 'retry-failed-payment';
		try {
			await this.api.retryFailedPayment();
			await this.accountController.refreshDetails();
		} finally {
			this.loadingStatus = false;
		}
	}

	async waitForPendingPaymentsToComplete(signal?: AbortSignal): Promise<void> {
		if(signal?.aborted) {
			return;
		}
		
		if(await this.api.getPendingPayment()) {
			await timeout(400);
			return this.waitForPendingPaymentsToComplete(signal);
		}
	}
}