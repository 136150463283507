export class ReferralController {
	private static KEY = 'referrer';
	readonly referredBy: string | undefined;

	constructor() {
		const fromUrl = new URLSearchParams(window.location.search).get(ReferralController.KEY);
		if(fromUrl) {
			localStorage.setItem(ReferralController.KEY, fromUrl);
			this.referredBy = fromUrl;
		} else {
			this.referredBy = localStorage.getItem(ReferralController.KEY) ?? undefined;
		}
	}
}