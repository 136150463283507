import type { JSX } from 'preact/jsx-runtime';
import type { ClassNameProp } from '../../../../util';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { formatIsoDate } from '../../../../util';


const getLastSeenClassName = (lastSeen: string | undefined): string | undefined => {
	if(!lastSeen) {
		return undefined;
	}

	const difference = dayjs().diff(new Date(lastSeen), 'day');
	if(difference <= 1) {
		return 'fg-good';
	}
	if(difference <= 7) {
		return 'fg-warn';
	}
	return 'fg-bad';
};

export const AdminUserLastSeenDate = (p: ClassNameProp & { date: string }): JSX.Element => {
	return (
		<span className={ classNames(getLastSeenClassName(p.date), p.className) }>
			{ formatIsoDate(p.date) }
		</span>
	);
};
