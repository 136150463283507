import { Content, EmailSupportLink, Panel, Typography } from '../atoms';
import { Alert } from '../molecules';
import './error-page.scss';


export const ErrorPage = () => {
	return (
		<Content className="error-page" width="sm">
			<Panel className="error-page__content">
				<Alert severity="error" message="Something went wrong" innerClassName="copy" size="xl">
					<Typography tag="p">
						Sorry, an error occurred while processing your request.
					</Typography>
					<Typography tag="p">
						Our team have been notified. If the issue persists, or you have any
						concerns, please <EmailSupportLink>contact our support</EmailSupportLink>.
					</Typography>
				</Alert>
			</Panel>

		</Content>
	);
};