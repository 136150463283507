import type { ChildrenProp, ClassNameProp } from '../../../util';


export type EmailSupportLinkProps = ClassNameProp & ChildrenProp & {
	subject?: string;
};
export const EmailSupportLink = ({ className, subject, children }: EmailSupportLinkProps) => (
	<a
		className={ className }
		href={ `mailto:support@brng.net${subject ? `?subject=${subject}`: ''}` }
	>
		{ children }
	</a>
);