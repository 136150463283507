import type { AccountDetails, AdminDetails, ChangePasswordErrorType, ChangePasswordRequest, InitResetPasswordRequest, LoginRequest, ResetPasswordErrorType, SignupRequest, SignupResponse, VerifyEmailErrorType, VerifyEmailRequest, ActivateBillingSetupResult, UpdatePaymentMethodSetupResult } from '@bearing-ctrl/common';


export class UnauthorizedError extends Error {
	constructor(path: string) {
		super('Unauthorized ' + path);
		this.name = '401';
	}
}

export class ForbiddenError extends Error {
	constructor(path: string) {
		super('Forbidden ' + path);
		this.name = '403';
	}
}

type Method =  'GET' | 'POST' | 'PUT' | 'DELETE';
type Path = `/${string}`;

export class ApiClient {
	public async fetchRaw(method: Method, path: Path, body?: Record<string, unknown>): Promise<Response> {
		path = '/api' + path as Path;
		const headers: Record<string, string> = {};
		if(body) {
			headers['content-type'] = 'application/json';
		}

		const response = await fetch(path, {
			method,
			headers,
			body: body && JSON.stringify(body),
		});
		switch(response.status) {
			case 401:
				throw new UnauthorizedError(path);
			case 403:
				throw new ForbiddenError(path);
			default:
				return response;
		}
	}

	public async fetch<T = unknown>(method: Method, path: Path, body?: Record<string, unknown>): Promise<T> {
		const response = await this.fetchRaw(method, path, body);
		if(!response.ok) {
			throw new Error(`Bad response from ${method} ${path}: ${response.status} ${response.statusText}}`);
		}

		return response.json();
	}

	async login(loginRequest: LoginRequest): Promise<boolean> {
		try {
			await this.fetch('POST', '/session', loginRequest);
			return true;
		} catch(err) {
			if(err instanceof UnauthorizedError) {
				return false;
			}

			throw err;
		}
	}

	async logout(): Promise<void> {
		await this.fetch('DELETE', '/session');
	}

	async signup(request: SignupRequest): Promise<SignupResponse> {
		const response = await this.fetchRaw('POST', '/users', request);
		return response.json();
	}
	
	async changePassword(request: ChangePasswordRequest): Promise<'ok' | ChangePasswordErrorType> {
		const response = await this.fetchRaw('PUT', '/users/active/password', request);
		if(response.ok) {
			return 'ok';
		}

		const body = await response.json();
		return body.error;
	}
	
	async verifyEmail(request: VerifyEmailRequest): Promise<'ok' | VerifyEmailErrorType> {
		const response = await this.fetchRaw('POST', '/users/active/verify-email', request);
		if(response.ok) {
			return 'ok';
		}

		const body = await response.json();
		return body.error;
	}
	
	async resendVerificationEmail(): Promise<boolean> {
		const response = await this.fetchRaw('POST', '/users/active/resend-verification-email');
		return response.ok;
	}
	
	async requestPasswordReset(request: InitResetPasswordRequest): Promise<void> {
		await this.fetch('POST', '/users/reset-password', request);
	}

	async checkPasswordResetCode(code: string): Promise<boolean> {
		const response = await this.fetchRaw('GET', `/users/reset-password/${code}`);
		return response.ok;
	}

	async resetPassword(code: string, password: string): Promise<'ok' | ResetPasswordErrorType> {
		const response = await this.fetchRaw('POST', `/users/reset-password/${code}`, { password });
		if(response.ok) {
			return 'ok';
		}

		const body = await response.json();
		return body.error;
	}
	
	getAccountDetails(): Promise<AccountDetails> {
		return this.fetch('GET', '/account');
	}

	async activateBilling(): Promise<ActivateBillingSetupResult> {
		return this.fetch('POST', '/billing');
	}

	async updatePaymentMethod(): Promise<UpdatePaymentMethodSetupResult> {
		return this.fetch('PUT', '/billing');
	}

	async cancelBilling(): Promise<void> {
		return this.fetch('DELETE', '/billing');
	}

	async retryFailedPayment(): Promise<void> {
		return this.fetch('POST', '/billing/retry');
	}
	
	async getAdmin(): Promise<AdminDetails | null> {
		const response = await this.fetchRaw('GET', '/admin');
		if(!response.ok) {
			return null;
		}

		return response.json();
	}

	async getPendingPayment(): Promise<boolean> {
		const response = await this.fetchRaw('GET', '/billing/pending-payment');
		return response.ok;
	}

	async clientError(details: Record<string, unknown>): Promise<void> {
		try {
			await this.fetchRaw('POST', '/client-error', details);
		} catch {
			// Bad times.
		}
	}
}