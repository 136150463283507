import type { ChildrenProp, ClassNameProp } from '../../../../util';
import type { IconName } from '../../atoms';
import classNames from 'classnames';
import { Divider , Heading, Icon, Panel } from '../../atoms';
import './admin-section.scss';


export type AdminSectionProps = ClassNameProp & ChildrenProp & {
	innerClassName?: string;
	title: string;
	icon: IconName;
};
export const AdminSection = ({ className, innerClassName, title, icon, children }: AdminSectionProps) => (
	<Panel className={ classNames('admin-section', className) } opaque>
		<header className="admin-section__header">
			<Icon className="admin-section__icon" icon={ icon } size="lg"/>
			<Heading className="admin-section__title" level={ 2 }>{ title }</Heading>
		</header>
		<Divider/>
		<div className={ classNames('admin-section__content', innerClassName) }>
			{ children }
		</div>
	</Panel>
);