import type { ApiClient } from '../api';
import { callback, observable, subject } from 'ecce-preact';


export type ErrorControllerConfig = {
	api: ApiClient;
};

@subject()
export class ErrorController {
	private readonly api: ApiClient;
	
	#error: Error | null = null;
	@observable()
	get error(): Error | null { return this.#error; }
	private set error(value: Error | null) { this.#error = value; }

	constructor({ api }: ErrorControllerConfig) {
		this.api = api;

		window.addEventListener('unhandledrejection', ev => {
			console.error('unhandledrejection:', ev.reason); // eslint-disable-line no-console
			this.handleError(ev.reason, 'unhandled rejection');
		});

		window.addEventListener('error', ev => {
			console.error('error:', ev.error); // eslint-disable-line no-console
			this.handleError(ev.error, 'uncaught exception');
		});
	}
	
	@callback
	handleError(err: unknown, type: string) {
		const error = err instanceof Error ? err : new Error('(non-error) ' + String(err));

		if(!this.shouldReportError(error)) {
			return;
		}

		this.error = error;

		this.api.clientError({
			type,
			path: window.location.pathname,
			name: this.error.name,
			message: this.error.message,
			stack: this.error.stack,
		});
	}

	private shouldReportError(error: Error) {
		if(error.stack?.includes('chrome-extension://')) {
			return false;
		}

		return true;
	}
}