import { Content, Heading } from '../../atoms';
import { ChangePasswordForm } from '../../organisms';
import { DashboardTemplate } from '../../templates/dashboard-template';



export const DashboardAccount = () => (
	<DashboardTemplate title="Account">
		<section>
			<Heading level={ 2 }>Change Password</Heading>
			<Content width="xs">
				<ChangePasswordForm/>
			</Content>
		</section>
	</DashboardTemplate>
);