import type { JSX } from 'preact/jsx-runtime';
import type { ChildrenProp } from '../../../../../util';
import type { IconName } from '../../../atoms';
import type { AlertSeverity } from '../../../molecules';
import { useMemo } from 'preact/hooks';
import { useAccountController, useAccountDetails } from '../../../../../controllers';
import { formatDate } from '../../../../../util';
import { Bold, Divider, EmailSupportLink, Panel } from '../../../atoms';
import { Alert, EmailFailureDetails } from '../../../molecules';


type AccountNotificationProps = ChildrenProp & {
	message: string;
	severity: AlertSeverity;
	icon?: IconName | JSX.Element;
};
const AccountNotification = ({ message, severity, icon, children }: AccountNotificationProps) => (
	<Panel tag="li" opaque>
		<Alert
			severity={ severity }
			innerClassName="copy"
			icon={ icon }
			message={ message }
			size="lg"
		>
			{children}
		</Alert>
	</Panel>
);

export const AccountNotifications = () => {
	const accountController = useAccountController();
	const { activatedLicense, beta, verifiedEmail, email, billing } = useAccountDetails();
	
	const notifications = useMemo<JSX.Element[]>(() => {
		const notifications: JSX.Element[] = [];

		if(billing.billingStatus === 'cancelled') {
			notifications.push(
				<AccountNotification
					severity="warning"
					message="Billing Cancelled"
				>
					<p>
						Your license will expire on <strong>{ formatDate(billing.licenseExpiresDate!) }</strong>,&nbsp;
						when you will move back to the trial license.
					</p>
					<p>
						You can <a href="/billing">reactivate billing</a> at any time.
					</p>
				</AccountNotification>
			);
		}
		
		if(billing.billingStatus === 'failed') {
			notifications.push(
				<AccountNotification
					severity="error"
					message="Payment Failed"
				>
					<p>
						We were unable to take payment for your most recent invoice{ billing.failedPayment && <strong>&nbsp;{ billing.failedPayment.reference }</strong> }.<br/>
						Please visit the <a href="/billing">billing page</a> to rectify the situation.
					</p>
					<p>If no action is taken, your license will expire on <strong>{ formatDate(billing.licenseExpiresDate!) }</strong>.</p>
				</AccountNotification>
			);
		}

		
		if(!activatedLicense) {
			notifications.push(
				<AccountNotification
					severity="info"
					message="Welcome to Bearing!"
					icon="tower"
				>
					<p>
						Read our <a href="/documentation/getting-started">Getting Started</a> guide
						for instructions on how to get <Bold>Bearing</Bold> up
						and running on your system.
					</p>
				</AccountNotification>
			);
		}
		
		if(!verifiedEmail) {
			notifications.push(
				<AccountNotification
					severity="info"
					message="Verify your email address"
					icon="mail"
				>
					<p>
						We've sent an email to <Bold>{ email }</Bold>.
						Click the link in that email to verify your email address.
					</p>
					<EmailFailureDetails resendEmail={ () => accountController.resendVerificationEmail() }/>
				</AccountNotification>
			);
		}

		if(beta) {
			notifications.push(
				<AccountNotification
					severity="info"
					icon="beta"
					message="Bearing is in beta"
				>
					<p>
						Thank you for participating in the <Bold>Bearing</Bold> beta!
						During the beta period, <Bold>Bearing</Bold> is free for unlimited use.
					</p>
					<p>
						If you have any suggestions, feedback or issues
						please <EmailSupportLink>get in touch</EmailSupportLink>.
					</p>
				</AccountNotification>
			);
		} else if(billing.billingStatus === 'trial' && verifiedEmail && activatedLicense) {
			notifications.push(
				<AccountNotification
					severity="info"
					message="Free Trial"
					icon="rocket"
				>
					{ billing.evaluation && (
						<>
							<p>Welcome to <Bold>Bearing</Bold>!</p>
							<p>
								To let you get a feel for <strong>Bearing</strong> and its
								features, you have a 3 month evaluation period, during which you are
								able to use the full version of <strong>Bearing</strong> for free.
							</p>

							<p>
								Your evaluation period ends on <Bold>{formatDate(billing.evaluation.endDate)}</Bold>.
								If you wish to continue using <Bold>Bearing</Bold> after
								this date, you can <a href="/billing">activate billing</a> on
								your account at any time.
							</p>
						</>
					)}
					{ !billing.evaluation && (
						<>
							<p>
								Whilst on the <Bold>Free Trial</Bold> you can view your 5 busiest sites.
							</p>
							<p>
								To unlock all of <Bold>Bearing</Bold>'s features, please <a href="/billing">activate billing</a> for your account.
							</p>
						</>
					)}
				</AccountNotification>
			);
		}
		
		return notifications;
	}, [accountController, activatedLicense, beta, billing.billingStatus, billing.evaluation, billing.failedPayment, billing.licenseExpiresDate, email, verifiedEmail]);
	
	if(!notifications.length) {
		return null;
	}

	return (
		<>
			<ul className="stack--sm">
				{ notifications }
			</ul>
			<Divider/>
		</>
	);
};