import type { RouteProps } from '../../util';
import { useSubject } from 'ecce-preact';
import { useAccountController } from '../../controllers/controllers-context';
import { DashboardHome, Landing } from '../components';


export const HomeRoute = (_: RouteProps) => {
	const accountController = useAccountController();
	useSubject(accountController, 'state');

	switch(accountController.state.kind) {
		case 'loading':
			return null;
		case 'ok':
			return <DashboardHome/>;
		case 'unauthorized':
			return <Landing/>;
	}
};