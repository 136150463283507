import type { ComponentChildren, JSX } from 'preact';


export type HtmlTag = keyof JSX.IntrinsicElements;

export type ClassNameProp = { className?: string };
export type ChildrenProp = { children?: ComponentChildren | undefined };
export type TagProp = { tag?: HtmlTag };
export type RouteProps = { path: string } & Record<string, string>;

export class NullContextError extends Error {
	constructor(contextName: string, providerName: string) {
		super(`${contextName} was null; missing <${providerName}>?`);
		this.name = 'NullContextError';
	}
}