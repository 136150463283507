import type { RouteProps } from '../../util';
import { useSubject } from 'ecce-preact';
import { useEffect } from 'preact/hooks';
import { route } from 'preact-router';
import { useControllers } from '../../controllers';
import { AdminPage } from '../components/pages/admin-page';


export const AdminRoute = (_: RouteProps) => {
	const { adminController, accountController } = useControllers();
	useSubject(adminController, 'state');
	useSubject(accountController, 'state');

	useEffect(() => {
		if(accountController.state.kind === 'unauthorized') {
			route('/login');
			return;
		}
		
		if(accountController.state.kind === 'ok') {
			adminController.init();
		}
	}, [accountController.state.kind, adminController]);

	switch(adminController.state.kind) {
		case 'init':
		case 'loading':
			return null;
		case 'ok':
			return <AdminPage/>;
	}
};