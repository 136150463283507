import type { BillingStatus } from '@bearing-ctrl/common';
import type { AdminDevTools } from '../../../../controllers/admin-controller';
import { BILLING_STATUSES } from '@bearing-ctrl/common/src/domain';
import dayjs from 'dayjs';
import { useSubject } from 'ecce-preact';
import { useState } from 'preact/hooks';
import { useAdminController } from '../../../../controllers';
import { useBreakpoint } from '../../../../util';
import { Button, Divider, Icon, Input, Typography } from '../../atoms';
import { AdminSection } from './admin-section';
import './admin-dev-tools.scss';


type DevToolProps = {
	devTools: AdminDevTools
};
const DevGenerateFakeCompanyDataForm = ({ devTools }: DevToolProps) => {
	const [ companyName, setCompanyName ] = useState('');

	const handleSubmit = (ev: Event) => {
		ev.preventDefault();
		devTools.fakeData(companyName);
	};
	
	return (
		<form className="horizontal-form" onSubmit={ handleSubmit }>
			<Input
				value={ companyName }
				onValueChange={ setCompanyName }
				title="Company Name"
				placeholder="Company Name"
				required
			/>
			<Button type="submit">Generate Fake Company Data</Button>
		</form>
	);
};

const DevSetBillingStatusForm = ({ devTools }: DevToolProps) => {
	const [ billingStatus, setBillingStatus ] = useState<BillingStatus>('beta');
	
	const handleSubmit = (ev: Event) => {
		ev.preventDefault();
		devTools.setBillingStatus(billingStatus);
	};
	
	return (
		<form className="horizontal-form" onSubmit={ handleSubmit }>
			<select
				value={ billingStatus }
				onChange={ ev => setBillingStatus(ev.currentTarget.value as BillingStatus) }
			>
				{ BILLING_STATUSES.map(billingStatus => (
					<option value={ billingStatus } key={ billingStatus }>
						{ billingStatus }
					</option>
				))}
			</select>
			<Button type="submit">Set billing status</Button>
		</form>
	);
};

type DevBillingCronRowProps = DevToolProps & {
	days: number;
	field: 'licenseExpires' | 'evaluationEnd';
	cronJobName?: string;
};
const DevBillingCronRow = ({ devTools, field, days, cronJobName }: DevBillingCronRowProps) => {
	
	const handleLicenseExpiresClick = () => {
		switch(field) {
			case 'licenseExpires':
				devTools.setLicenseExpires(days);
				break;
			case 'evaluationEnd':
				devTools.setEvaluationEnd(days);
				break;
		}
	};
	
	const handleCronJobClick = () => {
		if(cronJobName) {
			devTools.runBillingCronJob(cronJobName);
		}
	};
	
	return (
		<div className="horizontal">
			<Button onClick={ handleLicenseExpiresClick }>
				<Typography font="mono" weight="bold">user.{field}</Typography>&nbsp;
				<Typography>in <strong>{days}</strong> days</Typography>
			</Button>
			{ cronJobName && (
				<>
					<Icon icon="arrow-right"/>
					<Button onClick={ handleCronJobClick }>
						<Typography>Run</Typography>&nbsp;
						<Typography font="mono" weight="bold">{ cronJobName }()</Typography>
					</Button>
				</>
			)}
		</div>
	);
};

export const AdminDevToolsView = () => {
	const adminController = useAdminController();
	useSubject(adminController, 'state');

	const large = useBreakpoint('md');

	if(!large || adminController.state.kind !== 'ok' || !adminController.devTools) {
		return null;
	}

	return (
		<AdminSection
			className="admin-dev-tools theme-page surface"
			title="Dev Tools"
			icon="tool"
		>
			<section>
				<div>
					<label>Target user&nbsp;</label>
					<select
						value={ adminController.devTools.targetUser }
						onChange={ ev => adminController.devTools!.targetUser = ev.currentTarget.value }
					>
						{ adminController.state.details.allUserEmailAddresses.map(email => (
							<option value={ email } key={ email }>{email}</option>
						)) }
					</select>
				</div>

				<Divider/>

				<DevGenerateFakeCompanyDataForm devTools={ adminController.devTools }/>
				<DevSetBillingStatusForm devTools={ adminController.devTools }/>

				<Divider/>

				<DevBillingCronRow
					devTools={ adminController.devTools }
					field="licenseExpires"
					days={ 5 }
					cronJobName="renewBillingSetup"
				/>
				<DevBillingCronRow
					devTools={ adminController.devTools }
					field="licenseExpires"
					days={ 2 }
					cronJobName="renewBillingPayment"
				/>
				<DevBillingCronRow
					devTools={ adminController.devTools }
					field="licenseExpires"
					days={ 0 }
					cronJobName="expireLicenses"
				/>

				<Divider/>

				<DevBillingCronRow
					devTools={ adminController.devTools }
					field="evaluationEnd"
					days={ dayjs().add(3, 'months').diff(new Date(), 'days')  }
				/>
				<DevBillingCronRow
					devTools={ adminController.devTools }
					field="evaluationEnd"
					days={ 7 }
					cronJobName="sendEvaluationPeriodWarningEmails"
				/>

				<DevBillingCronRow
					devTools={ adminController.devTools }
					field="evaluationEnd"
					days={ 0 }
					cronJobName="endEvaluationPeriods"
				/>

				<Divider/>

				<Button onClick={ adminController.devTools.resetAllBillingInformation }>
					Reset all billing information
				</Button>
			</section>
		</AdminSection>
	);
};